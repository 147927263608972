import React, {useState, useEffect} from 'react';
import {Box,Card,CardContent,IconButton,Tooltip,Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {GeneralesContext} from '../context/GeneralesContext'
import {AuthContext} from '../context/AuthContext'
import SearchIcon from '@material-ui/icons/Search';
import IntervaloSelect from './IntervaloSelect';
import TablaNotificacion from './TablaNotificacion';
import NuevaAlerta from './NuevaAlerta';
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { toast } from 'react-toastify';
import Bitacora from "./BitacoraTipAuto";
import moment from 'moment';
const ENDPOINT = "https://sockets.daduva.mx:5000";

const useStyles = makeStyles((theme)=>({
    root: {
      width: '100%',
    },    
    title: {
      fontSize: 28,
    },
    title2: {
        fontSize: 18,
      },
    subTitle2: {
        fontSize: 12,
      },
    pos: {
      marginBottom: 12,
    },
    addButton: {
        marginRight: theme.spacing(2),       
      },
      altura2: {
        height: 70,
        //width:'100%',
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura3: {
        height: 70,
        marginTop:0,
        paddingTop:0,
       // width:275
      },
      altura1: {
        height: 90,        
      },
  }));
   
export default function NotificacionInicio(){
  const classes = useStyles();
  let history = useHistory();
  const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta     
  const [fechasSeleccionadas, setFechasSeleccionadas] = useState({inicio:new Date(),final:new Date()});
  const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
  const {usuario,guardarUsuario}=React.useContext(AuthContext);
  const [buscar, setBuscar] = useState(true);
  const [actualizar, setActualizar] = useState(false)
  const [contAux, setContAux] = useState(0)
  const [tituloNuevo, setTituloNuevo] = useState("")
  const [modalNuevo, setModalNuevo] = useState(false);
  const [response, setResponse] = useState("");
  const[seleccionado,setSeleccionado]=useState([])
  const [modalBitacora,setModalBitacora]= useState(false);    
  const [tituloInfo, setTituloInfo] = useState("")
  const [subtInfo, setSubtInfo] = useState("")
  useEffect(() => {
    const usu = localStorage.getItem('UsuarioNom');
    const nvl= localStorage.getItem('Tipo'); 
    const veriSesion =  () => {            
      let auxValidacion=authUser()
      if( !auxValidacion    ||(usu===undefined || usu===''|| usu===null ) 
        || ( nvl === undefined || nvl === '' || nvl === null ) )
      { guardarUsuario([])				
        guardarGenerales([])    
        localStorage.clear();            	             
        history.push("/")	                	
      }
      else
      {   
        if (usuario.length==0) 
        {
          guardarUsuario({Nombre:usu,nivel:nvl});                                                     
        }        
        //document.title = 'Lista de Alertas'                     
        guardarGenerales({...generales,Titulo:'Lista de Alertas'})
      }            
    }        
    veriSesion();	   
    
  }, [])
  
  useEffect(()=>{
    
    
    socket.on("AlertTipPendiente", data => {      
      //console.log("un nuevo Tip");
    
      let alertTip=JSON.parse(data)
      //console.log(alertTip);
      setSeleccionado({...alertTip,Descr:"TIP Automático",Observ:"Registro desde APP.",Id:alertTip.AlertId})
      let mensaje="Nuevo Tip Registrado \n"+alertTip.AlertId
      generarLista()
      toast.warning(mensaje,{
        pauseOnFocusLoss: false,                    
        toastId: `nvo-tip`+alertTip.AlertId,
        onClick:()=>abrirModaBitacora()
      })      

    });
    socket.on("AlertBitacoraNewUpdate", data => {          
    
      generarLista()          
    });

    
    return () => socket.disconnect();   
  })

  const generarLista = e =>{
    if(contAux===0){
      setContAux(1)
    }
    setBuscar(!buscar)
  }

  function abrirModal(){
    setModalNuevo(!modalNuevo)
    setTituloNuevo("Nueva Alerta")
  }
  
  function activarActualizacion(){
  
      setActualizar(!actualizar)
    
  }

  function abrirModaBitacora(){
    let fecha = moment.utc(seleccionado.fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(seleccionado.fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`Tip Automatico  `;
    setTituloInfo(titulo)
    setSubtInfo(fecha)
    setModalBitacora(!modalBitacora)
  }

  const modalBit = modalBitacora ? 
                <Bitacora seleccionado={seleccionado} titulo={tituloInfo} 
                  subtitulo={subtInfo} setModalAbierto={setModalBitacora} 
                  modalAbierto={modalBitacora}
                /> : null;

  const modNvo = modalNuevo ? <NuevaAlerta modalAbierto={modalNuevo} setModalAbierto={setModalNuevo}
                                titulo={tituloNuevo} generarLista={generarLista} />
                              :null
  //Descomentar el ícono de AddCircle para ver la sección de crear                                          
  return (
  <Box>
    <Box>
      <Card className={classes.altura2}>
        <CardContent>
          <Box display="flex" flexDirection="row">     
            <Box>
              <Tooltip title="Nueva Alerta">
                <IconButton color="primary"  aria-label="add circle" 
                  component="span" onClick={abrirModal}>                
                    <AddCircleOutlineIcon/>                
                </IconButton>                                                                                                                              
              </Tooltip>
            </Box>           
            <Box >
              <Tooltip title="Buscar">
                <IconButton color="primary"  aria-label="add circle" 
                  component="span" onClick={generarLista}
                >                
                  <SearchIcon/>                
                </IconButton>                                                                                                                              
              </Tooltip>
            </Box>
            <Box component="span" >                                                                                                                                            
              <IntervaloSelect fechasSeleccionadas={fechasSeleccionadas} 
                setFechasSeleccionadas={setFechasSeleccionadas} />
            </Box> 
            <Box pt={1} pl={1}>
              {/* <IconButton color="primary"  aria-label="add circle" 
                component="span">
                  <Tooltip onClick={activarActualizacion}
                    title={!actualizar ? 'Activar Actualización Automática' : "Desactivar Actualización Automática"} 
                  >
                    <RefreshIcon/>
                  </Tooltip>
              </IconButton>   */}  
              {!actualizar ?
                <Tooltip title='Activar Actualización Automática'  >
                  <Button variant="contained" color="primary" size="small" onClick={activarActualizacion}>
                    Activar
                  </Button>
                </Tooltip>
                :<Tooltip title='Desactivar Actualización Automática'  >
                  <Button variant="outlined" color="primary" size="small" onClick={activarActualizacion}>
                    Desactivar
                  </Button>
                </Tooltip>
              }
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
    <Box p={1}>
      <TablaNotificacion buscar={buscar} fechasSeleccionadas={fechasSeleccionadas} actualizar={actualizar} conta={contAux}/>
    </Box>
    {modNvo}
    {modalBit}
  </Box>
  )
}