import {createTheme} from '@material-ui/core/styles'
let colorBarra="#0F82EE";
let colorBarraHover="#3f9bf1";
let colorBackBarra="#ECFAFB";
export function generarTema(colorTema) {  

const Tema = createTheme( {
  palette:
  { type:(colorTema ? 'dark' : 'light'),
    primary:{
      light: '#3f9bf1',
      main:'#0F82EE',
      dark:'#0a5ba6',        
      contrastText: '#fff'
    }
    ,secondary:
    {
      light: '#3eb8e6',
      main:'#0EA7E0',
      dark:'#09749c',        
      contrastText:'#fff'
    },
    success: 
    {
      light: '#049b4f',
      main:'#06DE72',
      dark:'#37e48e',        
      contrastText:' rgba(0, 0, 0, 0.87)'
    },
  },
  //modifique el multiline para que se expanda la caja
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {           // el hexadecimal  es para el backgroundColor de la barra  #fff1e3
          //el hexadecimal  es para el color de la barra #0EA7E0 
          //el hexadecimal  es para el color del hover de la barra #c4e6f2 
          scrollbarColor: colorBarra+" "+colorBarraHover, //"#0EA7E0 #c4e6f2",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: colorBarra,
            minHeight: 24,
            border: "3px solid "+colorBarra,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: colorBackBarra,
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: colorBarraHover,
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: colorBackBarra,
          },
        },
      },
    },
  }
})

return Tema
}