import React from 'react'
import Menu from '@material-ui/core/Menu';


const MenuEstudio = ({children,valorCursor,handleClose}) => {    
    
    return (     
        <Menu
          keepMounted
          open={valorCursor.mouseY !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            valorCursor.mouseY !== null && valorCursor.mouseX !== null
              ? { top: valorCursor.mouseY, left: valorCursor.mouseX }
              : undefined
          }
        >
         {children}
        </Menu>      
    )
}

export default MenuEstudio