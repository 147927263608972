import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Polyline,InfoWindow,InfoBox,
        //Autocomplete,,
        Polygon,Marker } from '@react-google-maps/api';
import { Typography ,Box, Paper} from '@material-ui/core';
import moment from 'moment'
const mapContainerStyle = {
  height: "500px",
  width: "100%"//"900px"
}

const center = {
  lat: 19.5721573, lng: -99.2726426
  //19.592062391482873,-98.91206954361832
}
const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
                    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]

const MapaMonitAlerta = ({Seleccionado,tamanio,listObjetos,libreriaG,listCuadrante,listGpsUnidad,PoligEntrada,listCamara,listRepetidor,listTorres,listNvoCam}) => {
  //const [gps, setGps] = useState([])
  //const [rutaPol, setRutaPol] = useState([]);
  //const [autocomplete, setAutocomplete] = useState([]);
  let altuTamMax=825
  let anchoTamMax=1535
  const [auxMarcador, setAuxMarcador] = useState(center);
  const [auxZoom, setAuxZoom] = useState(13);
  const [listMarcador, setListMarcador] = useState([]);
  //const [PoligEntrada, setPoligEntrada] = useState([]);
  //const [listCamara, setListCamara] = useState([]);
  const [listGen, setListGen] = useState([]);
  //  const [listRepetidor, setListRepetidor] = useState([]);
  //const [listTorres, setListTorres] = useState([]);
  //const [listNvoCam, setListNvoCam] = useState([]);
  const [grpoCuadrante, setGrpoCuadrante] = useState([]);
  const [grpoSector, setGrpoSector] = useState([]);
  const [auxColores, setAuxColores] = useState(arreColores);
  const [listInfoBox, setListInfoBox] = useState([]);
  //let autocomplete = [];
  let arreRutas=[];     
  useEffect(()=>
  {       
    let arregloSector=[]   
    let auxListCuadrante=[]
    let auxListSect=[]
    let seccion=0
    let auxCua1=[]
  
    //console.log(listCuadrante);
    Seleccionado.forEach(sector => {          
      //arreRutas=generaCoordenada(sector)
      let auxCua2=listCuadrante.filter((auxSec)=> auxSec.SecId===sector.Id)
      let axC1= listCuadrante.filter((auxCuadr)=>auxCuadr.CuadId===sector.Id)
      let auxCua3=[]
      auxCua2.forEach((grpCua)=>{
        if (grpCua.Lat!==0 && grpCua.Lon !==0 ) {
          let auxCua=generaCoordenadaDinam(grpCua.Lat,grpCua.Lon) 
          auxCua3.push(auxCua)  
        }        
      })
      let auxCua5=[]
      axC1.forEach((grpCua)=>{
        if (grpCua.Lat!==0 && grpCua.Lon !==0 ) 
        {
          let auxCua=generaCoordenadaDinam(grpCua.Lat,grpCua.Lon) 
          auxCua5.push(auxCua)  
        }        
      })
      auxListCuadrante.push({cuadrante:auxCua5,grpOrig:axC1,Color:sector.Color})
      auxListSect.push({cuadrante:auxCua3,grpOrig:auxCua2,Color:sector.Color})
      arreRutas= generaCoordenadaDinam(sector.Lat,sector.Lon) // ordena sector     
      arregloSector.push({coordenada:arreRutas,original:sector})
    });

    auxListCuadrante=[]
     let axC1= listCuadrante.filter((auxCuadr)=>auxCuadr.TipoId===2 )
     let CuadId=axC1[0].CuadId
     let CuadPaths=[]     
      axC1.forEach(element => {
        if (CuadId!==element.CuadId ) 
        {
          auxListCuadrante.push({coordenada:CuadPaths,original:element})
          CuadPaths=[]
          CuadId=element.CuadId
        } 
        else 
        {
          let pathCuad1=generaCoordenadaDinam(element.Lat,element.Lon) 
          CuadPaths.push(pathCuad1)
        }
      });

      let auxNumSecc = auxListCuadrante.length
      if (auxNumSecc > arreColores.length) 
      { let auxArregloColor=[] 
        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        setAuxColores(auxArregloColor)
      }  
    //console.log(axC1);
    

    
    //console.log(auxListSect);
     //console.log(auxListCuadrante);

    /*let auxListCamara=[]
    let auxListGen=[]
    let auxListVia=[]
    let auxListRepetidor=[]
    let auxListTorres=[]
    let auxListNvoCam=[]
    
     listObjetos.forEach((elemento)=>{
      if (elemento.Lan !== 0 && elemento.Lon!==0 && elemento.TipoId!==4) 
      {        
        let auxPrev=generaCoordenada(elemento)
        let valida=true
        if (valida && elemento.TipoId===1) 
        { valida=false
          auxListCamara.push({coordenada:auxPrev,original:elemento})
        }
        if (valida && elemento.TipoId===3) 
        { valida=false
          auxListRepetidor.push({coordenada:auxPrev,original:elemento})
        }
        if (valida && elemento.TipoId===5) 
        { valida=false
          auxListTorres.push({coordenada:auxPrev,original:elemento})
        }
        if (valida && elemento.TipoId===2) 
        { valida=false
          auxListNvoCam.push({coordenada:auxPrev,original:elemento})
        }
        else
        {
          auxListGen.push({coordenada:auxPrev,original:elemento})
        }        
      }
      else
      {
        if (elemento.TipoId===4 && elemento.detalle) 
        { let auxPolyne=[]
          elemento.detalle.forEach((auxVia)=>{
            if (auxVia.DetLat!==0 && auxVia.DetLon!==0) 
            {                               
              let auxP1= generaCoordenadaVia(auxVia)
              auxPolyne.push(auxP1)
            }            
          })
          auxListVia.push({coordenada:auxPolyne,original:elemento})
        }  
      }
    }) */
     //console.log(auxListCamara); auxListCuadrante
    setGrpoCuadrante(auxListCuadrante)
    setGrpoSector(auxListSect)    
    //setListNvoCam(auxListNvoCam)
    //setListTorres(auxListTorres)
    //setListRepetidor(auxListRepetidor)
    //setListCamara(auxListCamara)
    //setListGen(auxListGen)
    //setPoligEntrada(auxListVia)
    //setAuxMarcador({ lat: Seleccionado[0].Lat ,  lng:  Seleccionado[0].Lon})
    setListMarcador(arregloSector)
  },[Seleccionado]);

  useEffect(()=>{
    //console.log(listGpsUnidad);
    let auxNvoList=[]
    listInfoBox.forEach((info)=>{
      let aux=listGpsUnidad.filter((unidad)=>unidad.usuPatrulla===info.usuPatrulla)
      let nvo=[]
      if (aux.length !== 0 ) {
        //nvo=formatoInfo(aux[0])  
        nvo=formatoInfo2(aux[0],info.valor)  
        auxNvoList.push(nvo)
      }    
    } )
    if (auxNvoList.length!==0) 
    { 
      setListInfoBox(auxNvoList)
      console.log(auxNvoList);
    }
  },[listGpsUnidad]) 


  const formatoInfo=(info)=>{    
    let auxArr=[]
    const txt="Unidad "+info.usuPatrulla
    const txt2="Ultima Actualización: "+moment(info.fecha).format("hh:mm:ss")    
    //console.log(info);
    //let auxCoordenada={lat:info.coordenada.lat+0.0000500,lng:info.coordenada.lng}   
    let auxCoordenada={lat:info.coordenada.lat,lng:info.coordenada.lng}   
    auxArr={coordenada:auxCoordenada,texto:txt,texto2:txt2,fecha:info.fecha,usuPatrulla:info.usuPatrulla}
    //setListInfoBox(auxArr)    
    //console.log(auxArr);
    return auxArr
  }

  const formatoInfo2=(info,valor)=>{    
    let auxArr=[]
    const txt="Unidad "+info.usuPatrulla
    const txt2="Ultima Actualización: "+moment(info.fecha).format("hh:mm:ss")    
    //console.log(info);
    //let auxCoordenada={lat:info.coordenada.lat+0.0000500,lng:info.coordenada.lng}   
    let auxCoordenada={lat:info.coordenada.lat,lng:info.coordenada.lng}   
    auxArr={coordenada:auxCoordenada,texto:txt,texto2:txt2,fecha:info.fecha,usuPatrulla:info.usuPatrulla,valor:valor}
    //setListInfoBox(auxArr)    
    //console.log(auxArr);
    return auxArr
  }

  const generaCoordenadaDinam=(auxLat,auxLng)=>{
    let coordenada=[]
    let aux1=auxLat;            
    aux1=parseFloat(aux1)
    let aux2=auxLng;            
    aux2=parseFloat(aux2)        
    coordenada={ lat : aux1, lng :aux2 }
  return coordenada  
  }

  const generaCoordenada=(elemento)=>{
    let coordenada=[]
    let aux1=elemento.Lan;            
    aux1=parseFloat(aux1)
    let aux2=elemento.Lon;            
    aux2=parseFloat(aux2)        
    coordenada={ lat : aux1, lng :aux2 }
  return coordenada  
  }

  const generaCoordenadaVia=(elemento)=>{
    let coordenada=[]
    let aux1=elemento.DetLat;            
    aux1=parseFloat(aux1)
    let aux2=elemento.DetLon;            
    aux2=parseFloat(aux2)        
    coordenada={ lat : aux1, lng :aux2 }
  return coordenada  
  }

  /* const onLoad =(respuesta)=> {  
    setAutocomplete(respuesta);  
  } */

  /* const onPlaceChanged =() =>{ //auto complete para traer las posibles respuestas
    if ( autocomplete !== null||autocomplete.length>0  ) {
      console.log(autocomplete.getPlace())
      console.log(`location[${JSON.stringify(autocomplete.getPlace().geometry.location)}]`);
      //console.log(`location[${JSON.stringify(autocomplete.getPlace().formatted_address)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      setPromDir(autocomplete.getPlace().formatted_address)
      setGps(autocomplete.getPlace().geometry.location);        
      setPromGPS(autocomplete.getPlace().geometry.location);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  } */
  const onLoadPolygono = marker => {
    //console.log('Poligono: ', marker)
  }
  const image = { url: "marcador.svg"};    
  const imageUnidad = { url: "LOGO_POLICIA_ATIZAPAN_mini.svg"};    
  const imageCam = { url: "./marcadores/icon_camara_neutro.png"};
  const imageCamNaranja = { url: "./marcadores/icon_camara_naranja.png"};
  const imageCamAzul = { url: "./marcadores/icon_camara_azul.png"};
  const imageCamVerde = { url: "./marcadores/icon_camara_verde.png"};
  const imageRepetidor = { url: "./marcadores/icon_repetidor.png"};
  const imageTorreAmari = { url: "./marcadores/torre_amarilla.png"};
  const imageTorreNegra = { url: "./marcadores/torre_negra.png"};

  const tipoTorres=(elemnto)=>{
    let auxImg=imageTorreAmari
    let valida=true
   //console.log(elemnto);    
    if (valida && elemnto.Color === "#FF000000") 
    { valida=false
      auxImg=imageTorreNegra
    }
    return auxImg
  }
  const ordenImgNvoCam=(elemnto)=>{
    let auxImg=image
    let valida=true    
    if (valida && elemnto.Orden > 0) 
    { valida=false
      auxImg={ url: "./marcadores/camara_num/icon_"+elemnto.Orden+".png"}
    }
    return auxImg
  }

  const ordenSector=(elemnto)=>{
    let auxImg=image
    let valida=true    
    if (valida ) 
    { valida=false
      //console.log(elemnto);
      auxImg={ url: "./marcadores/Sector/icon_s"+elemnto.Alias+".png"}
    }
    return auxImg
  }

  const tipoImgCam=(elemnto)=>{
    let auxImg=imageCam
    let valida=true    
    if (valida && elemnto.Color==="#FF0F9D58") 
    { valida=false    
      auxImg=imageCamVerde
    }
    if (valida && elemnto.Color==="#FFDE6002") 
    { valida=false      
      auxImg=imageCamNaranja
    }
    if (valida && elemnto.Color==="#FF2D8AD4") 
    { valida=false      
      auxImg=imageCamAzul
    }
    return auxImg
  }
  const options2 =(color)=> {  
    
    let auxCol="#"+color
    //console.log(auxCol);  
    const auxiliar={  
    fillColor:auxCol ,
    fillOpacity: 0.2,
    strokeColor: auxCol,
    strokeOpacity: 1,
    strokeWeight: 3.5,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const optionSector =(color)=> {  
    
    let auxCol="#"+color
    //console.log(auxCol);  
    const auxiliar={  
    fillColor:auxCol ,
    fillOpacity: 0.2,
    strokeColor: auxCol,
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }

  const optionCuadrante =(color)=> {  
    
    //let auxCol="#"+color
    let auxCol=color
    //console.log(auxCol);  
    const auxiliar={  
    fillColor:auxCol ,
    fillOpacity: 0.15,
    strokeColor: auxCol,
    strokeOpacity: 0.2,    
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const optionMapSector =(color)=> {  
     
    const auxiliar={  
    fillColor:"#880E4F" ,
    fillOpacity: 0.2,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 5,
    clickable: false,
    draggable: false,
    editable: false,visible: true,
    geodesic: false,
    zIndex: 1}
    return auxiliar;
  }
  const path = [
    {lat: 37.772, lng: -122.214},
    {lat: 21.291, lng: -157.821},
    {lat: -18.142, lng: 178.431},
    {lat: -27.467, lng: 153.027}
  ];
 
  
  const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: [
      {lat: 37.772, lng: -122.214},
      {lat: 21.291, lng: -157.821},
      {lat: -18.142, lng: 178.431},
      {lat: -27.467, lng: 153.027}
    ],
    zIndex: 1
  };

  const modCentroZoom=(sector)=>{
    setAuxZoom(14)
    setAuxMarcador(sector.coordenada)  
  }
  const objetosGeneral=()=>{
    return(
    <span>
      {listCamara.length !== 0 ? //objeto camara
        listCamara.map((sector,index)=>{
          return(
          <Marker style={{width:50+'px',height:50+'px'}}
            title={sector.original.Alias}   key={index}
            position={ sector.coordenada}  
            icon= {tipoImgCam(sector.original)}                   
          />
          )
        })
        :null
      }    
      {listRepetidor.length !== 0 ? //objeto repetidor
        listRepetidor.map((sector,index)=>{
          return(
          <Marker style={{width:50+'px',height:50+'px'}} key={index}
            title={sector.original.Alias} position={ sector.coordenada}  
            icon= {imageRepetidor} onClick={()=>console.log(sector.original)}            
          />
          )
        })
        :null
      }
      {listTorres.length!==0?     //objeto torre
        listTorres.map((sector,index)=>{
          return(
          <Marker style={{width:50+'px',height:50+'px'}}
            title={sector.original.Alias}   key={index}
            position={ sector.coordenada}  
            icon= {tipoTorres(sector.original)}                   
          />
          )
        })
      :null
      }    
      {listNvoCam.length!==0? //objeto camaras 1-150 
        listNvoCam.map((sector,index)=>{
          return(
          <Marker style={{width:50+'px',height:50+'px'}}
            title={sector.original.Alias}   key={index}
            position={ sector.coordenada}  
            icon= {ordenImgNvoCam(sector.original)}                   
          />
          )
        })
      :null
      }
    </span>
    )
  }
  
  const objetoSector=()=>{
    return(
    <span>
      { listMarcador.length !==0 ? //sectores
        listMarcador.map((sector,index)=>{     
          return(
          <Marker key={index}
            title={sector.original.Alias} //onLoad={onLoad} 
            position={ sector.coordenada} onClick={()=>modCentroZoom(sector)}
            icon= {ordenSector(sector.original)}                   
          />
          )
        })
        :null 
      }
    </span>
    )
  }

  const divStyle = {
    //background: `white`,
    border: `1px solid #ccc`,
    padding: 15
  }

  const guardarTxt=(info)=>{
    let listFiltrada=listInfoBox.filter((txt)=>txt.usuPatrulla===info.usuPatrulla)
    //console.log(listFiltrada);
    if ( listFiltrada.length === 0 ) 
    {      
      let auxArr=[]
      //console.log(info);     
      auxArr=formatoInfo(info)
      setListInfoBox([...listInfoBox,auxArr])
      //console.log(auxArr);
    }
  }

  const guardarTxt2=(info)=>{
    let listFiltrada=listInfoBox.filter((txt)=>txt.usuPatrulla!==info.usuPatrulla)
    //console.log(listFiltrada);       
      let auxArr=[]
      //console.log(info);     
      auxArr=formatoInfo2(info,true)
      setListInfoBox([...listFiltrada,auxArr])
      //console.log(auxArr)
    
  }
  
  const cerrarInfoTxt=(info)=>{
    let auxArr=[]
    let listFiltrada=listInfoBox.filter((txt)=>txt.usuPatrulla!==info.usuPatrulla)  
    //console.log(info);  
    auxArr=formatoInfo2(info,false)
    setListInfoBox([...listFiltrada,auxArr])
    setListInfoBox(listFiltrada)    
  }
  
  const optionInfoTxt= (valor)=> { 
    return{/* closeBoxURL: '',  */enableEventPropagation: valor }
  };

  return (    
  <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" language="spanish" libraries={libreriaG}> 
  <GoogleMap 
    id="mapa-monit-alerta" center={auxMarcador}    zoom={auxZoom}        
    mapContainerStyle={tamanio ? tamanio:mapContainerStyle} 
  >                
    {objetoSector()}
    {objetosGeneral()}

    { listGpsUnidad.length!==0?    //marcadores posicion de unidad
      listGpsUnidad.map((unidad,index)=>{
        return(
        <Marker  title={"Unidad "+unidad.usuPatrulla} onClick={()=>guardarTxt(unidad)}
          position={ unidad.coordenada} icon= {image}     key={index}              
        />
        )
      })
      :null 
    }

    { listInfoBox.length!==0?    //infoWindow posicion de unidad
      listInfoBox.map((unidad,index)=>{
        return(
        <InfoBox  title={"Unidad "+unidad.usuPatrulla}  key={index} 
          position={ unidad.coordenada} icon= {image}  onCloseClick={()=>cerrarInfoTxt(unidad)}   
          option={optionInfoTxt(unidad.valor)}
        >
          <Paper>
            <Box p={1}>              
            <Typography variant='p'>{unidad.texto}</Typography>    
            <br/>
            <Typography variant='p'>{unidad.texto2}</Typography>            
           </Box>
           </Paper>
        </InfoBox>
        )
      })
      :null 
    }
    {/* listGen.length!==0?
      listGen.map((sector,index)=>{
        return(
        <Marker  title={'Ninguno'} 
          position={ sector.coordenada} icon= {image}                   
        />
        )
      })
    :null */
    }

    { PoligEntrada !== null && PoligEntrada.length > 0 ?  //lista de polylineas de vias
      PoligEntrada.map((auxVia,index)=>{       
        return(
        <Polyline key={index}
          onLoad={onLoadPolygono} path={auxVia.coordenada}
          options={options2(auxVia.original.Color.slice(3))}
        />)
      })                    
      :null  
    }
     { grpoSector.length!==0 ?           //lista de polylineas de sector 
      grpoSector.map((grupo,index)=>{       
        return(
        <Polyline onLoad={onLoadPolygono} path={grupo.cuadrante} 
          options={optionSector(grupo.Color.slice(3))} key={index}
        />)
      })                    
      :null  
    }  
     {  grpoCuadrante !== null && grpoCuadrante.length > 0 ?   //lista de poligonos de Cuadrante
      grpoCuadrante.map((auxVia,index)=>{  
        //console.log(auxVia);     
        return(<Polygon
          onLoad={onLoadPolygono} paths={auxVia.coordenada}
          options={optionCuadrante(auxColores[index])}
          key={index}
        />)
      })                    
      :null    
    }  
      
    {/*  grpoSector.length!==0 ?           //lista de poligonos de sector 
      grpoSector.map((grupo)=>{       
        return(
        <Polygon onLoad={onLoadPolygono} paths={grupo.cuadrante} 
          options={optionSector(grupo.Color.slice(3))}
        />)
      })                    
      :null   */
    }
    
    {/* <Polyline
      //onLoad={onLoad}
      path={path2}
      options={options}
    />  */} 
  </GoogleMap>
  </LoadScript>  
  );
}
 
export default MapaMonitAlerta;