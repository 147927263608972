import React,{useEffect,useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import { TextField,IconButton,Box,Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaElemento from './TablaElementos'
import NvoElemento from './NuevoElemento'
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
    
    buscador:{
    width:'30em'
    },
 
  });
const ElementoInicio = () => {
    let history = useHistory();
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
   // let history = useHistory();  
    const[usrBusq,setUsrBusq]=useState({nombre:''});
    const{nombre}=usrBusq
    const classes = useStyles();
    const[actualizar,setActualizar]=useState(false);
    const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false); 
    //const [modalNvoCliAbierto,setModalNvoCliAbierto]= useState(false); 
    useEffect(() => {
        const usu = localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo'); 
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion || (usu===undefined || usu===''|| usu===null ) 
            || ( nvl === undefined || nvl === '' || nvl === null )    )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                         
                    
                }        
                //document.title = 'Listado Elementos'                                 
                guardarGenerales({...generales,Titulo:'Lista de Elementos'})                                            
            }            
        }        
      veriSesion();	   
        
    }, [])

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[:$!%-({})/*|]');
        //let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z0-9.-_]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) //&& !expMenos.test(e.target.value) 
            &&!expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
        {
            setUsrBusq({
                ...usrBusq, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }         
    };

    function abrirModalNvoUsr(){  
        console.log("abriendo modal");  
        setModalNvoUsrAbierto(true);    
    }

    let colorLetra="#0F82EE"
    const modNvoUsr = modalNvoUsrAbierto ? 
                        ( <NvoElemento modalAbierto={modalNvoUsrAbierto}  
                            setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Elemento'}
                            //setActualizar={setActualizar} actualizar={actualizar}
                        />
                        ): null; 
    return (
    <Box pt={1}>
        <TextField className={classes.buscador}
            name="nombre" placeholder="Elemento"
            value={nombre} onChange={onChange}
        />
        <Tooltip title={`Actualizar lista elementos`}>
            <IconButton aria-label="actualizar" onClick={() => setActualizar(!actualizar)} 
                component="span" size="small" 
            >
                <RefreshIcon style={{color:colorLetra  }}/>
            </IconButton>
        </Tooltip>
        <Tooltip title={`Crear Elemento`}>
            <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                component="span" size="small" 
            >
                <AddCircleOutlineIcon style={{color:colorLetra }} />  
            </IconButton>
        </Tooltip>
        <br/> 
        {nombre.length >= 3 ? <TablaElemento auxNombre={nombre} auxActualizar={actualizar} setActualizar={setActualizar}/>:null}
        {modNvoUsr}
    </Box>
    )
}

export default ElementoInicio
