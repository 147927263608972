import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Button,InputLabel, MenuItem, TextField,Select, Box} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import { toast } from 'react-toastify';
import io from "socket.io-client";
const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'28.5em'
    },
    formSelect: {
      width:'11rem'
    },
    formStatus: {
        width:'8rem'
    },
    formSelectTipo: {
        width:'13.5rem'
    },
    formSelectElemento:{
        width:'25rem'
    },
    formSelectUnidad:{
        width:'16.5rem'
    },
    formObs:{
        marginTop:'1.7em',
        width:'30em',
        height:'6em'
    },
  }));
  const ENDPOINT = "https://sockets.daduva.mx:5000";


export default function NuevaAlerta({modalAbierto,setModalAbierto,titulo,generarLista}){
    const classes = useStyles();
    let history = useHistory(); 
    const usuID= localStorage.getItem('UsuId');    
    const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta    
    const source = axios.CancelToken.source();    
    const [listaElementos, setListaElementos] = useState([])
    const [listaCategoria, setListaCategoria] = useState([])
    const [listaTipo, setListaTipo] = useState([])
    const [listaUnidad, setListaUnidad] = useState([])
    const [listaStatus, setListaStatus] = useState([])
    const [espera, setEspera] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [auxId, setAuxId] = useState(0);
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [registro, setRegistro] = useState({  Tipo: 0, Status:0, Categoria: 0, Unidad: 0,
                                                Elemento: 0, Lat: 19.599229, Lon: -98.904685,
                                                Direcc: "", Descr: "", Observ: "",
                                            })
    const {Tipo,Status,Categoria,Unidad,Elemento,Lat,Lon,Direcc,Descr,Observ} = registro
    //const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    let expNopermitida = new RegExp('[%{}*|`]');  
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');     
    let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]'); 

    useEffect(()=>{       
        const getStatus = () => {
            const data = qs.stringify({
                usuario:usuID,   
                idDispositivo:'w845sdc15ds3dc1s'
            });
            const url = "alerta-status";
      
            function respuesta(aux) {
                if(aux[0].Id>-1){
                    setListaStatus(aux)
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        };  
        
        const getTipoAlerta = () => {
            const data = qs.stringify({
                usuario:usuID,   
                idDispositivo:'w845sdc15ds3dc1s'
            });
            const url = "alerta-tipo";
      
            function respuesta(aux) {
                if(aux[0].Id>-1){                        
                    setListaTipo(aux)
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        }; 

        const getListaCategoria = () => {
            const data = qs.stringify({
                usuario:usuID,   
                idDispositivo:'w845sdc15ds3dc1s'
            });
            const url = "alerta-categoria";
      
            function respuesta(aux) {
                if(aux[0].Id>-1){
                    setListaCategoria(aux)
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        }; 

        const getListaUnidad = () => {
            const data = qs.stringify({
                usuario:usuID,   
                idDispositivo:'w845sdc15ds3dc1s'
            });
            const url = "seguridad-unidad-list";
      
            function respuesta(aux) {
                if(aux[0].Id>-1){
                    aux.sort(function (a, b) { return a.Id - b.Id; });   
                    setListaUnidad(aux)
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        }; 

        const getElementos = () => {
            const data = qs.stringify({
                usuario:usuID,   
                idDispositivo:'w845sdc15ds3dc1s'
            });
            const url = "seguridad-elemento-list";
      
            function respuesta(aux) {
                if(aux[0].Id>-1){
                    aux.sort(function (a, b) { return a.Id - b.Id; });   
                    setListaElementos(aux)
                }
            }
            llamadaApiToken(data, url, respuesta,ErrorGeneral,history,source) 
        }; 
       
        getStatus()
        getTipoAlerta()
        getListaCategoria()
        getListaUnidad()
        getElementos()

        return ()=> { source.cancel() }    
    },[]);


    function onChangeTipo(e){
        setRegistro({
            ...registro,
            Tipo: e.target.value
        })
    }

    function onChangeCategoria(e){
        setRegistro({
            ...registro,
            Categoria: e.target.value
        })
    }

    function onChangeStatus(e){
        setRegistro({
            ...registro,
            Status: e.target.value
        })
    }


    function onChangeUnidad(e){
        setRegistro({
            ...registro,
            Unidad: e.target.value
        })
    }

    function onChangeElemento(e){
        setRegistro({
            ...registro,
            Elemento: e.target.value
        })
    }

    function onChangeDireccion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Direcc: e.target.value
            })
        }
    }

    function onChangeDescripcion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Descr: e.target.value
            })
        }
    }
    function onChangeObservacion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Observ: e.target.value
            })
        }
    }

    function mensajeAdv(texto){        
        setTipoAdvertencia("warning")
        setMensaje(texto)
        setAviso(true)        
    }

    function verificarTipo(tp){
        let validado=false;
        if(parseInt(tp)!==0){
            validado=true
        }else{            
            mensajeAdv("Seleccione un tipo válido")            
            validado=false;
        }
        return validado;
    }

    function verificarCategoria(cat){
        let validado=false;
        if(parseInt(cat)!==0){
            validado=true
        }else{
            mensajeAdv("Seleccione una categoría válida")                   
            validado=false;
        }
        return validado;
    }

    function verificarStatus(stat){
        let validado=false;
        if(parseInt(stat)!==0){
            validado=true
        }else{
            mensajeAdv("Seleccione un Estatus válido")                   
            validado=false;
        }
        return validado;
    }

    function verificarUnidad(uni){
        let validado=false;
        if(parseInt(uni)!==0){
            validado=true
        }else{
            mensajeAdv("Seleccione una unidad válida")                   
            validado=false;
        }
        return validado;
    }

    function verificarElemento(ele){
        let validado=false;
        if(parseInt(ele)!==0){
            validado=true
        }else{
            mensajeAdv("Seleccione un elemento válido")              
            validado=false;
        }
        return validado;
    }

    function verificarObservacion(ob){
        let validado=false;
        if(ob && ob!==" " && ob.trim().length>0){
            validado=true
        }else{
            mensajeAdv("Ingrese Observación")              
            validado=false;
        }
        return validado;
    }

    function verificarDirección(dir){
        let validado=false;
        if(dir && dir!==" " &&dir.trim().length>0){
            validado=true
        }else{
            mensajeAdv("Ingrese Dirección")                
            validado=false;
        }
        return validado;
    }

    function handleSubmition(){
        if(verificarTipo(Tipo) && verificarCategoria(Categoria) && verificarStatus(Status) && verificarUnidad(Unidad)
            && verificarElemento(Elemento) && verificarDirección(Direcc) && verificarObservacion(Observ) )
        {
            registrarAlerta()
        }
    }

    const envioSocket = (auxID) => {
        //console.log("antes de emit");        
        //e.preventDefault()
        socket.emit(
          "AlertNew",
          JSON.stringify({
            origen:"com.daduva.atizapanweb",
            AlertId:auxID,
            UserId:usuID,
            SegId:Elemento,            
          })
        );
       
    };

    const registrarAlerta = () => {
        const data = qs.stringify({
            Usuario:usuID,   
            Usrsystem:usuID,
            Tipo:Tipo,
            Status: Status,
            Categoria:Categoria,
            Unidad:Unidad,
            Elemento: Elemento,
            Lat: Lat,
            Lon:Lon,
            Direccion:Direcc,
            Descripcion:Descr,
            Observacion:Observ,
            Motivocancel:'',
            idDispositivo:'w845sdc15ds3dc1s'
        });
        const url = "alerta-nuevo";
  
        function respuesta(aux) {
            if(aux.respuesta===1){
                const mensaje="Alerta agregada con éxito"
                setTipoAdvertencia("success")
                setMensaje(mensaje)
                toast.success(mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setAuxId(aux.mensaje)
                setTimeout(() => {
                    envioSocket(aux.mensaje)
                }, 200);
                setAviso(true)
                setModalAbierto(false)
                generarLista()
            }
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
    };     

    return (
    <Modal titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={handleSubmition}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'l'} cargando={espera}
    >
        <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                {listaTipo.length!=0 ? 
                <Grid item sx={8}>
                <FormControl className={classes.formSelectTipo}>
                    <InputLabel id="Tipo">Tipo</InputLabel>
                    <Select label="Tipo" id="Tipo" name="Tipo"
                      value={Tipo} onChange={onChangeTipo}
                    >
                      {listaTipo.map((tp, index) => (
                        <MenuItem value={tp.Id} key={tp.Id}>
                          <em>{tp.Nom}</em>
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Grid> :null
                }
                {listaCategoria.length!=0 ? 
                <Grid item sx={1}>
                <FormControl className={classes.formSelect}>
                    <InputLabel id="Categoria">Categoría</InputLabel>
                    <Select label="Categoria" id="Categoria" name="Categoria"
                      value={Categoria} onChange={onChangeCategoria}
                    >
                      {listaCategoria.map((cat, index) => (
                        <MenuItem value={cat.Id} key={cat.Id}>
                          <em>{cat.Nom}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                
            </Grid>
            <Grid container spacing={1}>
                {listaStatus.length!=0 ? 
                <Grid item sx={1}>
                <FormControl className={classes.formStatus}>
                    <InputLabel id="Status">Estatus</InputLabel>
                    <Select label="Status" id="Status" name="Status"
                      value={Status} onChange={onChangeStatus}
                    >
                      {listaStatus.map((stat, index) => (
                        <MenuItem value={stat.Id} key={stat.Id}>
                          <em>{stat.Nom}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                {listaUnidad.length!=0 ? 
                <Grid item sx={6}>
                <FormControl className={classes.formSelectUnidad}>
                    <InputLabel id="Unidad">Unidad</InputLabel>
                    <Select label="Unidad" id="Unidad" name="Unidad"
                      value={Unidad} onChange={onChangeUnidad}
                    >
                      {listaUnidad.map((uni, index) => (
                        <MenuItem value={uni.Id} key={uni.Id}>
                          <em>{uni.Placa+" "+uni.Observ}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                
            </Grid>
            {listaElementos.length!=0 ? 
                <Grid item sx={1}>
                <FormControl className={classes.formSelectElemento}>
                    <InputLabel id="Elemento">Elemento</InputLabel>
                    <Select label="Elemento" id="Elemento" name="Elemento"
                      value={Elemento} onChange={onChangeElemento}
                    >
                      {listaElementos.map((ele, index) => (
                        <MenuItem value={ele.Id} key={ele.Id}>
                          <em>{ele.Nombre+" "+ele.Paterno+" "+ele.Materno}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
            <Grid container spacing={1}>
                <Grid item>
                    <FormControl className={classes.formAnotacion}>
                        <TextField required label="Dirección" value={Direcc} 
                            onChange={onChangeDireccion}id={'Direcc'}/> 
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" flexDirection="column">
                <textarea className={classes.formObs} placeholder="Descripción"
                value={Descr} onChange={onChangeDescripcion} id={'Descr'}
                multiline rows={2} maxRows={4}/>
                <textarea className={classes.formObs} placeholder="Observación"
                value={Observ} onChange={onChangeObservacion} id={'Observ'}
                multiline rows={2} maxRows={4}/>
            </Box>
        </form>
    </Modal>
    )
}