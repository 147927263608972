import React from 'react';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, IconButton, Button, Tooltip,Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'; 
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import Brightness3Icon from '@material-ui/icons/Brightness3';
const drawerWidth = 240;

const useStyles =makeStyles(theme=>({    
    menuButton: {
        marginRight: theme.spacing(2),
        /*[theme.breakpoints.up('sm')]: {
            display: 'none',
          },*/
      },
    mititulo:{
        flexGrow:1,
        textAlign:'center',
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            //width: `calc(100% - ${drawerWidth}px)`,
            width: `calc(100%)`,
            marginLeft: drawerWidth,
          },
      },
      appBarSinloguear: {
        
            width: `100% `,
            //marginLeft: drawerWidth,          
      },
}))

const Navbar = (propiedades) => {
    let history = useHistory();
    const {guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);
    const misclases=useStyles();  

    const cambiarTema=(auxColor)=>{
        propiedades.setColorTema(!auxColor)
        localStorage.setItem('ColorTheme',!auxColor);
        //console.log(!auxColor);       
    }

    const CerrarSesion =e =>{
        e.preventDefault();        
        localStorage.clear();
        guardarGenerales([])       	
        guardarUsuario([])
        history.replace("/")			
    }
    let auxTitulo="Pruebas - "
    auxTitulo=""
    return (

    <AppBar className={ propiedades.usuario.length !== 0 ? misclases.appBar
        : misclases.appBarSinloguear } position="fixed" color="primary"
    >
        <Toolbar>
        {propiedades.usuario.length !== 0  ?
            <IconButton color="inherit" onClick={()=>propiedades.funcionAbrir()} 
                className={misclases.menuButton} aria-label="menu"  size='small'
            >
            <MenuIcon/>
        </IconButton>
        :null
        }        
        <Typography variant="h6" className={misclases.mititulo}>
            {generales.length!==0  && generales?.Titulo !== undefined ?  auxTitulo+generales.Titulo : auxTitulo+'Atizapan TIP'}
        </Typography>
        {propiedades.usuario.length !== 0  ?
            <Box>
                <Tooltip title={!propiedades.colorTema?`Cambiar a Modo Oscuro`:`Cambiar a Modo Claro`}>
                <IconButton color="inherit" onClick={()=>cambiarTema(propiedades.colorTema)} 
                    className={misclases.menuButton} aria-label="menu"  size='small'
                >
                    {propiedades.colorTema ?  <BrightnessLowIcon/>:<Brightness3Icon/> }
                </IconButton>
                </Tooltip>
                <Button variant="text" color="inherit" onClick={CerrarSesion}>
                    Cerrar Sesión
                </Button>
            </Box>
            :null
        }        
        </Toolbar>
    </AppBar>
        
    );
}
 
export default Navbar;