import React from 'react'
import {List,ListItem,Collapse,Typography,LinearProgress,
    ListItemText,Divider,Box, Paper} from "@material-ui/core";
import {ExpandLess,ExpandMore  } from '@material-ui/icons';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MapaAlerta from './MapaUnidSeg'
//import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from "socket.io-client";
import IntervaloSelect from './IntervaloSelect';
import TablaVirtual from './TablaVirtual';
import { grey } from '@material-ui/core/colors';
import { format } from 'date-fns'
const ENDPOINT = "https://sockets.daduva.mx:5000";

const useStyles = makeStyles((theme) => ({
    root: {
    //width: '100%',
      //maxWidth: 360,                  
      backgroundColor: theme.palette.background.paper,
      
    },
    miPaper:{
        backgroundColor: grey [700],
    },
    nested: {
      paddingLeft: theme.spacing(4),      
    },

  }));
const MonitUnidSeg = () => {
    const source = axios.CancelToken.source();    
    let history = useHistory();   
    const classes = useStyles();
    const[listObjeto,setListObjeto]=React.useState([])
    const[listCoodTipo,setListCoodTipo]=React.useState([])
    const[listSectorMap,setListSectorMap]=React.useState([])
    const[listObjetos,setListObjetos]=React.useState([])
    const[listCuadrante,setListCuadrante]=React.useState([])
    const[listGpsUnidad,setListGpsUnidad]=React.useState([])
    //const[listGpsUnidad2,setListGpsUnidad2]=React.useState([])
    const [listNvoCam, setListNvoCam] = React.useState([]);
    const [listTorres, setListTorres] = React.useState([]);
    const [listRepetidor, setListRepetidor] = React.useState([]);
    const [listCamara, setListCamara] = React.useState([]);
    const [listUnidades, setListUnidades] = React.useState([]);
    const [PoligEntrada, setPoligEntrada] = React.useState([]);
    let auxFMin=format(new Date( ), 'MM/dd/yyyy')
    let auxFMax=format(new Date( ), 'yyyy-MM-dd')+"T23:59"
    console.log(auxFMax);
    const [fechasSeleccionadas, setFechasSeleccionadas] = React.useState({inicio:auxFMin,final:auxFMax});

    const [listSeguimiento, setListSeguimiento] = React.useState([]);
   
    const libreriaG=[process.env.REACT_APP_GOOGLE_LIBRARIE]
    React.useEffect(()=>{
        let token =localStorage.getItem('token20') ;    
        let listObj =localStorage.getItem('listObj20') ;    
        let listSect =localStorage.getItem('listSector20') ;   
        let listCuad =localStorage.getItem('listCuad20') ;    
        let listUnid =localStorage.getItem('listUnid20') ;    
        function dentroError(error){
            console.log(`Error: ${error}`);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {   console.log('datos incorrectos' );  
            }
            else
            {   if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
        }

        async function llamaListCoordTipo(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                { let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCoodTipo(filtrado)
                }
            }).catch(error=>dentroError(error))
        }
        async function llamaListObjeto(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListObjeto(filtrado)
                }
            }).catch(error=>dentroError(error))
        }
        async function llamaListSectorMap(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-sector-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,                  
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    //let nvoFilt=[]
                   /*  aux.forEach(sector => {
                        nvoFilt.push({Lat,Lon})
                    }); */
                    localStorage.setItem('listSector20',JSON.stringify(filtrado));  
                    setListSectorMap(filtrado)
                }
            }).catch(error=>dentroError(error))
        }
        async function llamaListCuadrante(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,   
                    'tipo':0,               
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCuadrante(filtrado)
                    localStorage.setItem('listCuad20',JSON.stringify(filtrado));  
                    /* function prbC(){
                        console.log("dentro del toast")
                    }                                        
                    let mensaje="cargando datos"
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `prb-map01`,
                        onClick:()=>prbC()
                    }) */
                }
            }).catch(error=>dentroError(error))
        }
        async function llamaListObjetos(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,        
                    'tipo':0,          
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)  
                    //console.log(filtrado);           
                    localStorage.setItem('listObj20',JSON.stringify(filtrado));  
                   // generaCoordObj(filtrado)
                    setListObjetos(filtrado)                    
                }
            }).catch(error=>dentroError(error))
        }   
        async function llamaListUnidades(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-unidad-list`;   
            let dataInfo = qs.stringify({                                                
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)  
                    filtrado.sort(function (a, b) { return a.Id - b.Id; });
                    console.log(filtrado);           
                    localStorage.setItem('listUnid20',JSON.stringify(filtrado));  
                   // generaCoordObj(filtrado)
                   setListUnidades(filtrado)                    
                }
            }).catch(error=>dentroError(error))
        }        
        if (listObj===undefined || listObj===null) //existen las coordenadas de la lista de objetos
        {   //console.log("saltando filtro");      
            llamaListObjetos()   
        }
        else
        {   let auxList=JSON.parse(listObj)                 
            //console.log(auxList);
            //generaCoordObj(auxList)
            setListObjetos(auxList) 
        }
        //verificaLista(listSect,llamaListSectorMap,setListSectorMap) //existen las coordenadas de la lista de sectores
         if (listSect===undefined || listSect===null) 
        {   
            llamaListSectorMap()  
        }
        else
        {   let auxList=JSON.parse(listSect)                          
            setListSectorMap(auxList) 
        }
        // verificaLista(listCuad,llamaListCuadrante,setListCuadrante) //existen las coordenadas de la lista de cuadrantes
        if (listCuad===undefined || listCuad===null) 
        {   //console.log("saltando filtro3");      
            llamaListCuadrante()
        }
        else
        {   let auxList=JSON.parse(listCuad)                 
            //console.log(auxList);
            setListCuadrante(auxList) 
        } 
         //verificaLista(listUnid,llamaListUnidades,setListUnidades) //existen  la lista de unidades
        if (listUnid===undefined || listUnid===null) 
        {   //console.log("saltando filtro3");      
            llamaListUnidades()
        }
        else
        {   let auxList=JSON.parse(listUnid)                 
            //console.log(auxList);
            setListUnidades(auxList) 
        }
        llamaListObjeto()    
        llamaListCoordTipo()
                
    },[])    

    const verificaLista=(listaLocal,llamadaApi,setVariable)=>{
        if (listaLocal===undefined || listaLocal===null) //existen  la lista local
        {   console.log("saltando filtro",listaLocal);      
            llamadaApi()
        }
        else
        {   let auxList=JSON.parse(listaLocal)                 
            console.log(auxList);
            setVariable(auxList) 
        }
    }

  /*   React.useEffect(()=>{    
        const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta 

        socket.on("GPSUnidadActual", (GPSSocket) => {                  
            //console.log(JSON.parse(GPSSocket));            
            let auxGps=JSON.parse(GPSSocket)            
            setListGpsUnidad2([auxGps,...listGpsUnidad2])
             if (auxGps.Lat!==0 && auxGps.Lon !==0) 
            {   let nvoRegistro=[]
               // console.log(auxGps);
                let aux1 =generaCoordenadaDinam(auxGps.Lat ,auxGps.Lon)
                if (listGpsUnidad.length!==0) // verifica que tenga unidades registradas y filtra las que tienen actualizaciones
                {   let filtrado=listGpsUnidad.filter((gpUnidad)=> gpUnidad.usuPatrulla!==auxGps.UnidId)                    
                    filtrado.push({coordenada:aux1, usuPatrulla:auxGps.UnidId,fecha:auxGps.fecha })   
                    nvoRegistro=filtrado                    
                    setListGpsUnidad(nvoRegistro)
                }                
                else
                {    nvoRegistro.push({coordenada:aux1, usuPatrulla:auxGps.UnidId ,fecha:auxGps.fecha})                    
                    setListGpsUnidad(nvoRegistro)
                }
                
            }
        });   

        return () => socket.disconnect();     
    })   */  

    const generaCoordObj=(array)=>{        
        let auxListCamara=[]
        let auxListGen=[]
        let auxListVia=[]
        let auxListRepetidor=[]
        let auxListTorres=[]
        let auxListNvoCam=[]
        array.forEach((elemento)=>{
            if (elemento.Lan !== 0 && elemento.Lon!==0 && elemento.TipoId!==4) 
            {        
              let auxPrev=generaCoordenadaDinam(elemento.Lan,elemento.Lon)
              let valida=true
              if (valida && elemento.TipoId===1) 
              { valida=false
                auxListCamara.push({coordenada:auxPrev,original:elemento})
              }
              if (valida && elemento.TipoId===3) 
              { valida=false
                auxListRepetidor.push({coordenada:auxPrev,original:elemento})
              }
              if (valida && elemento.TipoId===5) 
              { valida=false
                auxListTorres.push({coordenada:auxPrev,original:elemento})
              }
              if (valida && elemento.TipoId===2) 
              { valida=false
                auxListNvoCam.push({coordenada:auxPrev,original:elemento})
              }
              else
              {
                auxListGen.push({coordenada:auxPrev,original:elemento})
              }        
            }
            else
            {
              if (elemento.TipoId===4 && elemento.detalle) 
              { let auxPolyne=[]
                elemento.detalle.forEach((auxVia)=>{
                  if (auxVia.DetLat!==0 && auxVia.DetLon!==0) 
                  {                               
                    let auxP1= generaCoordenadaDinam(auxVia.DetLat,auxVia.DetLon)
                    auxPolyne.push(auxP1)
                  }            
                })
                auxListVia.push({coordenada:auxPolyne,original:elemento})
              }  
            }
      
          })
                
        setListNvoCam(auxListNvoCam)
        //setListNvoCam([])
        setListTorres(auxListTorres)
        setListRepetidor(auxListRepetidor)
        setListCamara(auxListCamara)        
        setPoligEntrada(auxListVia) 
        //setListGen(auxListGen)                         
    }

    const generaCoordenadaDinam=(auxLat,auxLng)=>{
        let coordenada=[]
        let aux1=auxLat;            
        aux1=parseFloat(aux1)
        let aux2=auxLng;            
        aux2=parseFloat(aux2)        
        coordenada={ lat : aux1, lng :aux2 }
      return coordenada  
    }
 


    let altuTamMax =800
    const   tamanioH= (window.innerHeight<altuTamMax ? window.innerHeight-100: window.innerHeight - 100)
    return ( 
    <div>
        
    <Box width={100+'%'} display="flex">
        
        <Box width={15+'rem'}   style={{marginRight:1+'rem',minWidth:13+'rem'}}>
        <Paper   >
            <IntervaloSelect fechasSeleccionadas={fechasSeleccionadas} 
                setFechasSeleccionadas={setFechasSeleccionadas} 
            />
            {listUnidades.length!==0 ?
                <TablaVirtual listUnidades={listUnidades} setListSeguimiento={setListSeguimiento} 
                    fechasSeleccionadas={fechasSeleccionadas}
                />
            :null}
            </Paper>
        </Box>
        {  listSectorMap.length!==0  && listObjetos.length!==0 && listCuadrante.length!==0  ?
            <MapaAlerta  Seleccionado={listSectorMap} 
                tamanio={{height: tamanioH, width: "100%"}}
                listObjetos={ listObjetos  } listCuadrante={listCuadrante}
                listGpsUnidad={listGpsUnidad} PoligEntrada={PoligEntrada}
                listCamara={listCamara} listRepetidor={listRepetidor} listTorres={listTorres}
                listNvoCam={listNvoCam} libreriaG={libreriaG}  listSeguimiento={listSeguimiento}
            />
        :<Box mt={5} ><LinearProgress/></Box>   }
    </Box>
    
    </div>
    )
}

export default MonitUnidSeg