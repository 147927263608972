import React,{useEffect, useState} from 'react'
import {Table, TableCell, TableContainer, TableRow, TableHead, TableBody,TextField,
    Tooltip, IconButton,Box,LinearProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';            
import moment from 'moment';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    root: {
        //width: '100%',
    },
    container: {
        //maxHeight: 550,
        maxHeight: window.innerHeight-260,
        minHeight: 280,
        maxWidth:12+'rem',
        minWidth:10+'rem',
        marginRight:1+'rem'
    },
    container2: {
    maxWidth:'100%',
        maxHeight: 550,
        //maxHeight: window.innerHeight-170,
        //minHeight: 280,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#0F91C1"
        }
        },
    tableCell: {
        "$selected &": {
        color: "white"
        }
    },
    hover: {},
    selected:{}
});
const TablaVirtual = ({listUnidades,setListSeguimiento,fechasSeleccionadas}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
    const [nombre, setNombre] = useState("");
    const [espera, setEspera] = useState(false);
    let history = useHistory();   
    useEffect(()=>{
        //console.log("unidades",listUnidades);
        //setListSeguimiento([])
    },[])  

    const onChange = e =>
    {   setNombre(e.target.value)        
    };

    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} 
            style={estilo} 
        >
          {contenido}
        </TableCell>
        )
    }
    async function llamaListSeguimiento(unidad){        
        const source = axios.CancelToken.source();    
        let token =localStorage.getItem('token20') ;    
        let usuId =localStorage.getItem('UsuId') ;    
        setEspera(true)
        let fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DDTHH:mm"+":00");
        let fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DDTHH:mm"+":59");  
        let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-unidad-seguimiento-gps`;   
        let dataInfo = qs.stringify({         
                'Usuario':usuId,
                'Unidad':unidad,
                'FIni':fInicio,//+'T00:00:00',
                'FFin':fFinal,//+'T23:59:59',
                'idDispositivo':'w845sdc15ds3dc1s'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {          
            aux=  response.data;    
            if(aux[0].Id>-1)
            {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                
                if (filtrado.length!==0) {
                    setListSeguimiento(filtrado)    
                }
                else{//console.log(aux);
                    setListSeguimiento({vacio:true})
                }
                
            }
        }).catch(function (error) {          
            console.log(`Error: ${error}`);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }
            else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
        })
        setEspera(false)
    }
    function seleccionarId(e,auxId){
        e.preventDefault()
        setSelectedID(auxId)        
        setListSeguimiento([])
    }

    const btnLlamada=(e)=>{
        e.preventDefault()
              
        if (selectedID!=="" && selectedID>0) 
        {   //console.log(selectedID);
            setListSeguimiento([])
            llamaListSeguimiento(selectedID)    
        }
        else
        {
            let mensaje="Debe seleccionar una unidad "
            toast.error(mensaje,{
                position: "top-left",
                pauseOnFocusLoss: false,                    
                toastId: `unid-01`,            
            })
        }
        
    }

    function Renglon(props) {
        const { row } = props;  
        return(
        <TableRow onClick={ (e) => { seleccionarId(e,row.Id); } }
          className={classes.tableRow} selected={ selectedID === row.Id}          
          classes={{ hover: classes.hover, selected: classes.selected }}
        >                                                 
          {celda("center",{  width:20+'px' },row.Id)}               
          {celda("center",{ width:250+'px' },row.NumEco)}               
        </TableRow>
        )        
    }

    function generarTabla(resultados){
        return(
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>                                
                {celda("center",{ width:10+'px', color:'#0F82EE'} ,resultados.length)}          
                {celda("center",{ color:'#0F82EE',fontWeight:600} ,"Unidad")}                          
            </TableRow>
          </TableHead>
          <TableBody>
            {resultados.length!=0 ? (resultados.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
          </TableBody>
        </Table>
        </TableContainer>
        )
    }

    function filtro(listUnidades){        
        let auxCadena=nombre.trim();
       // console.log(auxCadena);
        let info=[];//datos[0];     
        let aux=  listUnidades.filter((dat)=>dat.NumEco.toUpperCase().search(auxCadena.toUpperCase() ) !== -1) 
        //console.log(aux);
        info.push(aux)             
        return generarTabla(aux);
    }

    const filtrado= nombre.length>=1 ? filtro(listUnidades) : generarTabla(listUnidades)
    const contenido = listUnidades.length!==0 ?  filtrado : null
    return (
    <>
        {  !espera ?
            <Box ml={1}>
            <TextField className={classes.formControl} name="nombre" 
                id="input-filtro-unid01" label="Unidad" autoComplete="off"
                value={nombre} onChange={onChange} variant="outlined" size="small"
                style={{marginTop:1.3+'rem',marginBottom:1+'rem',width:9+'rem'}}
            />            
            <Tooltip title="Buscar Ubicación">
                <IconButton color="primary"  aria-label="add circle" 
                component="span"  onClick={(e)=>btnLlamada(e)}  size="small"
                style={{marginTop:1.5+'rem' ,marginLeft:.5+'rem'}}
                >
                    <SearchIcon/> 
                </IconButton>    
            </Tooltip>            
        </Box>
        :<Box mt={3} mb={3} width={"90%"} ml={1}><LinearProgress/></Box>
        }
        {contenido}
    </>
    )
}

export default TablaVirtual
