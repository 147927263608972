import React,{useState,useEffect, useContext} from 'react'
import {Box,Collapse,IconButton,Paper,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow,Button,Card,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; 
import CreateIcon from '@material-ui/icons/Create'; 
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import EditarUsuario from './EditarUsuario'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import { Celda ,celdaFuncion} from '../generales/TablaGeneral';
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    {
      id:4,
      titulo:"No.",                                                
      alinear:'center'
    },     
    {
      id:5,
      titulo:"Usuario",                                                
      alinear:'center'
    },
    {
      id:15,
      titulo:"Sección",                                                
      alinear:'center'
    },
    {
      id:14,
      titulo:"Colonia",                                                
      alinear:'center'
    },
    {
      id:12,
      titulo:"CURP",                                                
      alinear:'center'
    },
    {
      id:7,
      titulo:"INE",                                                
      alinear:'center'
    }, 
    {
      id:11,
      titulo:"RFC",                                                
      alinear:'center'
    },   
    {
      id:13,
      titulo:"Telefono",                                                
      alinear:'center'
    },   
    {
      id:16,
      titulo:"Celular",                                                
      alinear:'center'
    },      
    {
      id:8,
      titulo:"Email",                                                
      alinear:'center'
    },
    {
      id:9,
      titulo:"Edad",                                                
      alinear:'center'
    },
    {
      id:6,
      titulo:"Genero",                                                
      alinear:'center'
    },
     
    {
      id:10,
      titulo:"F. Reg.",                                                
      alinear:'center'
    },        
  ];

  const colRegis=[ {campo:"Id",estilo:{ fontWeight:700,width:'40px', paddingRight:'2px'} },
    {campo:"auxNom", estilo:{ width:'250px',fontSize:'12px'}, align:"left" },
    {campo:"SeccionFolio", estilo:{width:'60px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"ColoniaNom", estilo:{ width:'60px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Curp", estilo:{ width:'150px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Ine", estilo:{ width:'150px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Rfc", estilo:{ width:'90px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Tel", estilo:{ width:'60px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Cel", estilo:{ width:'60px',paddingLeft:'.5rem',fontSize:'12px'} },
    {campo:"Email", estilo:{ width:'120px',fontSize:'12px',paddingLeft:'8px'} },
    {campo:"Edad", estilo:{ width:'30px',fontSize:'12px',paddingLeft:'5px'} },
    {campo:"Genero", estilo:{ width:'40px',paddingLeft:'.5rem'} },
  ]

const TablaUsuarios = ({auxNombre,auxActualizar,setActualizar}) => {
  const classes = useStyles();
  const colorLetra="#0F82EE"
  let history = useHistory();     
  const source = axios.CancelToken.source();    
  const [datos, guardarDatos] = useState([])  
  const[sinDatos,guardarSinDatos]= useState(true)
  const [espera,setEspera]= useState(false);
  const [selectedID, setSelectedID] = useState(null);    
  const [clienteSeleccionado,setClienteSeleccionado]= useState([]);    
  const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false);     
  const {usuario,guardarUsuario}=useContext(AuthContext);
  const {generales,guardarGenerales}=useContext(GeneralesContext); 
    
  useEffect(()=>
  { const usuID= localStorage.getItem('UsuId');
    const conectarJSon = () => {
      const data = qs.stringify({
        usuario:usuID,
        cadena:auxNombre,
        idDispositivo:'w845sdc15ds3dc1s'
      });
      const url = "usrpublico-list";

      function respuesta(aux) {
        if (aux[0].Id != -1) 
        { 
          guardarSinDatos(true)                                                           
          guardarDatos(aux)
        }
        else
        { 
          guardarSinDatos(false)       
        } 
      }
      llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
    };      
    
    conectarJSon()

    return ()=> {
      source.cancel();
    }                
  },[auxActualizar])

  const seleccionado=(auxId)=>{
    setSelectedID(auxId)   
  }
         
  function abrirModalEditCliente(auxClient)
  { setClienteSeleccionado(auxClient)
    //console.log("abriendo modal");  
    setModalEditCliAbierto(true);    
  }

  function Renglon(props)
  {
    const { row } = props;  
    const auxNom=`${row.Nombre} ${row.Paterno} ${row.Materno}`
    return (    
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}
     // onContextMenu={(event)=>guadarPosCursor(event,row)} 
      classes={{ hover: classes.hover, selected: classes.selected }}        
    > 
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Editar Usuario`}>
          <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
            {selectedID === row.Id ? 
              <CreateIcon style={{color:'white',width:18+'px'}} />
              :<CreateIcon style={{width:18+'px'}} color="primary"/>
            }
          </IconButton>                            
        </Tooltip>                                                                                                                                              
      </TableCell>             
      {colRegis.map((reg,index)=>
        <Celda align={reg?.align} estilo={reg.estilo} key={index+1}>
          {reg.campo !== "auxNom"? row[reg.campo] : auxNom}
        </Celda>
      )}          
      {celdaFuncion("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'},
          moment.utc( row.Freg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" 
        : moment.utc( row.Freg).format('DD/MM/YYYY HH:mm')       
      ,10,classes.tableCell)}                                                                                                
    </TableRow>            
    )
  }

  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>                                     
            <TableCell align="center" padding={'none'} style={{ color:colorLetra}}  >
              {auxlista.length}
            </TableCell>                          
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:colorLetra}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}

    const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            {columnas.map(row => {              
              return(  
              <TableCell style={{ backgroundColor:colorLetra,color:'white'}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRow} > 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Usuarios</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modEditCli = modalEditCliAbierto ? 
                      ( <EditarUsuario modalAbierto={modalEditCliAbierto}  
                          setModalAbierto={setModalEditCliAbierto} usrSelecc={clienteSeleccionado}
                          titulo={'Editar '+clienteSeleccionado.Nombre+' '+clienteSeleccionado.Paterno+' '+clienteSeleccionado.Materno}                          
                          setActualizar={setActualizar} actualizar={auxActualizar}
                        />
                      ): null;                                                                                                          
  return (
    <div style={{minWidth:1215+'px',marginTop:.5+'rem'}}>        
      {tabla}      
      {modEditCli}
    </div>
  )
}

export default TablaUsuarios
