import React,{useEffect, useState} from 'react'
import {Table, TableCell, TableContainer, TableRow, TableHead, TableBody,TextField,
    Tooltip, IconButton,Box,LinearProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';            
import moment from 'moment';
import {ordenCoordenadas} from '../funciones/Mapas'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
const useStyles = makeStyles({
    root: {
        //width: '100%',
    },
    container: {
        //maxHeight: 550,
        maxHeight: window.innerHeight-130,
        minHeight: 280,
        maxWidth:10.5+'rem',
        minWidth:9+'rem',
        marginRight:1+'rem'
    },
    container2: {
    maxWidth:'100%',
        maxHeight: 550,
        //maxHeight: window.innerHeight-170,
        //minHeight: 280,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#0F91C1"
        }
        },
    tableCell: {
        "$selected &": {
        color: "white"
        }
    },
    hover: {},
    selected:{}
});
const TablaUbicaciones = ({listSeguimiento,setUnidSeguimiento,setAuxMarcador,listAuxColor,obtenerColor,setAuxZoom}) => {
    const classes = useStyles();
    const [selectedID, setSelectedID] = useState(null);
            
    useEffect(()=>{
        //console.log("unidades",listUnidades);
        if (listSeguimiento.length>0) 
        {   let auxSeg=listSeguimiento[0]
            setSelectedID(auxSeg.Id)        
            let auxCoord=ordenCoordenadas(auxSeg.Lat,auxSeg.Lon)
            let auxArreglo=[]
            auxArreglo.push({coordenada:auxCoord,original:auxSeg})
            setAuxMarcador(auxCoord)
            setAuxZoom(18);
            setUnidSeguimiento(auxArreglo)
        }
        else
        {   setAuxMarcador({lat: 19.5721573, lng: -99.2726426})
            setUnidSeguimiento([])
            setSelectedID(null)
        }
        
    },[listSeguimiento])      

    const celda=(alinear,estilo,contenido)=>{
        return (
        <TableCell align={alinear} className={classes.tableCell} padding={'none'} 
            style={estilo} 
        >
          {contenido}
        </TableCell>
        )
    }    

    function seleccionarId(aux){
        setSelectedID(aux.Id)        
        let auxCoord=ordenCoordenadas(aux.Lat,aux.Lon)
        let auxArreglo=[]
        auxArreglo.push({coordenada:auxCoord,original:aux})
        //console.log(auxArreglo);
        setUnidSeguimiento([])
        setAuxZoom(18);
        setTimeout(() => {
            //console.log('This will run after 1 second!')       
            setUnidSeguimiento(auxArreglo)
          }, 200);  
        
        setAuxMarcador(auxCoord)
    }    

    function Renglon(props) {
        const { row ,indice} = props;  
        let hora=moment.utc(row.FReg).format("DD/MM HH:mm:ss");          
        //let auxColor=listAuxColor[indice].color
        
        let auxColor= selectedID === row.Id?"red" :obtenerColor(indice).color
        return(
        <TableRow onClick={ () => { seleccionarId(row); } }
          className={classes.tableRow} selected={ selectedID === row.Id}          
          classes={{ hover: classes.hover, selected: classes.selected }}
        >                                                            
          {celda("center",[],<FiberManualRecordIcon style={{color:auxColor,width:18+'px'}} />)}                         
          {celda("center",{ width:100+'px',fontSize:12+'px',paddingLeft:.5+'rem'},hora )}               
          {/* {celda("center",{  width:50+'px',fontSize:11+'px'},row.IdDisp)}          */}     
        </TableRow>
        )        
    }

    function generarTabla(resultados){
        return(
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>              
                {celda("center",{ width:10+'px', color:'#0F82EE'} ,`${resultados.length}`)}              
                {celda("center",{ width:10+'px', color:'#0F82EE'} ,"Fecha")}          
                {/* {celda("center",{ color:'#0F82EE',fontWeight:600} ,"Dispositivo")}     */}                      
            </TableRow>
          </TableHead>
          <TableBody>
            {resultados.length!=0 ? (resultados.map((row,index) => (
                <Renglon key={row.Id} row={row} indice={index}/>
            ))):null}
          </TableBody>
        </Table>
        </TableContainer>
        )
    }

  
    const contenido = listSeguimiento.length !== 0 ? generarTabla(listSeguimiento)  : null
    return (
    <>        
        {contenido}
    </>
    )
}

export default TablaUbicaciones