import React,{useState,useEffect} from 'react';
import { GoogleMap, LoadScript,Autocomplete,Polyline,Polygon, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "500px",
  width: "100%"//"900px"
}

const center = {
  lat: 20.091146,  lng:  -98.762333
  //19.592062391482873,-98.91206954361832

}
const MapaConsultaMarcador = ({poligonos,listaPromov,setPromDir,setPromGPS,centro,cambiaMarcador,setCambiaMarcador,Seleccionado}) => {
  const [gps, setGps] = useState([])
  const [rutaPol, setRutaPol] = useState([]);
  const [autocomplete, setAutocomplete] = useState([]);
  const [auxMarcador, setAuxMarcador] = useState(center);
  //let autocomplete = [];
  let arreRutas=[];   
  let arreRutaSeccion=[];   
  let arreMarcador=[];   
  let listaMarcadores=[];
  useEffect(()=>
  {   
   /*  if (poligonos)
    {
      let contador=0;
      let variable=0;
      poligonos.map((poligono) => {
        if (variable!==poligono.seccionId) {
          variable=poligono.seccionId;
          arreRutas=[]
          arreRutaSeccion.push(arreRutas);                        
        }
        if(poligono.seccionId===variable)
        { 
          let aux1=poligono.seccionLat;            
          aux1=parseFloat(aux1)
          let aux2=poligono.seccionLon;            
          aux2=parseFloat(aux2)
        // console.log(aux1,aux2 );
          arreRutas.push({ lat : aux1, lng :aux2 })
          
        }
          
      })
      //console.log(arreRutaSeccion.length);
      setRutaPol(arreRutaSeccion);
      let auxNumSecc = arreRutaSeccion.length
      if (auxNumSecc > arreColores.length) 
      {
        let auxArregloColor=[]
 
        for (let index = 0; index < auxNumSecc; index++) 
        { let nivel= (index % 18 === 0) ? (index / 18===1 ? 0 :17): (index % 18)
          auxArregloColor[index] = arreColores[nivel]          
        } 
        console.log(auxArregloColor);
        setAuxColores(auxArregloColor)
      }  
    } */
    let aux1=Seleccionado.Lat;            
    aux1=parseFloat(aux1)
    let aux2=Seleccionado.Lon;            
    aux2=parseFloat(aux2)        
    arreRutas={ lat : aux1, lng :aux2 }
    setAuxMarcador(arreRutas)
    //setCambiaMarcador(true)      
},[centro]);

  const onLoad =(respuesta)=> {
  
    setAutocomplete(respuesta);
  
  }

  const onPlaceChanged =() =>{
    if ( autocomplete !== null||autocomplete.length>0  ) {

      console.log(autocomplete.getPlace())
      console.log(`location[${JSON.stringify(autocomplete.getPlace().geometry.location)}]`);
      //console.log(`location[${JSON.stringify(autocomplete.getPlace().formatted_address)}]`)
        // this.gps=this.autocomplete.getPlace().geometry.location
      //console.log(`lat[${this.autocomplete.getPlace().location.lat}] lon[${this.autocomplete.getPlace().location.lng}]`)
      setPromDir(autocomplete.getPlace().formatted_address)
      setGps(autocomplete.getPlace().geometry.location);        
      setPromGPS(autocomplete.getPlace().geometry.location);
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }
  
 
 
  const image = { url: "marcador.svg"};
  const image2 = { url:"marcador_seleccionado.svg"  };

  return (    
    <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
      <GoogleMap
        id="searchbox-example"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={auxMarcador}
      >         
        {    /* cambiaMarcador&&listaPromov?
            listaPromov.map((marcador,index) => 
            {//console.log(parseFloat(marcador.promLat),parseFloat(marcador.promLon));
                let aux1=marcador.promLat;            
                aux1=parseFloat(aux1)
                let aux2=marcador.promLon;            
                aux2=parseFloat(aux2)            
                let arreMarcador=[];   
                arreMarcador= { lat : aux1, lng :aux2 }
                if (promoSeleccionado===marcador.promId){
                    return(
                        <Marker
                            onLoad={onLoad}
                            title={marcador.promNombre}
                            position={ arreMarcador}
                            icon= {image2}                            
                        />
                    )    
                }else{
                    //console.log(centro);
                    //console.log(arreMarcador);
                return(
                    <Marker
                        onLoad={onLoad}
                        title={marcador.promNombre}
                        position={ arreMarcador}
                    
                        icon= {image}
                    
                    />
                )}
            })      
        :null  */
        }   
        <Marker
            onLoad={onLoad}     title={'Ninguno'}
            position={ auxMarcador} icon= {image}                   
        />

  </GoogleMap>
</LoadScript>  );
}
 
export default MapaConsultaMarcador;
