import { Box, Card, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import ModalInformativo from '../generales/ModalInformativo'
import MapaAlerta  from './MapaAlerta'

const useStyles=makeStyles({
    cardTexto:{
        paddingTop:.125+'rem',
        paddingBottom:.125+'rem',
        textAlign:'center',  
        marginBottom:.5+'rem'      
    },
    texto:{
        fontWeight:700
    },
    cardMapa:{
        display:'flex',
        justifyContent:'center'
    },        

})
const ModalMapa = ({setModalAbierto,modalAbierto,titulo,seleccionado,subtitulo}) => {
    const miClase=useStyles()
    return (
        <ModalInformativo
            setModalAbierto={setModalAbierto}
            modalAbierto={modalAbierto} titulo={titulo}
            tamanio='md' colorFondo='#f5f5f5' subtitulo={subtitulo}
        >
            <Box // style={{width:50+'rem',height:50+'rem'}}
            >
                 <Card style={{marginBottom:.5+'rem'}}>
                <Typography variant="body2"  color="primary" style={{marginLeft:0.5+'rem',marginRight:1+'rem',fontWeight:700}}>
                    Descripción
                </Typography>
                <Typography variant="body2" >
                    {seleccionado.Descr}
                </Typography>
                
                {seleccionado.Observ && seleccionado.Observ.length!==0 ?
                <span>
                    <br/>
                <Typography variant="body2" color="primary" style={{marginLeft:0.5+'rem',marginRight:1+'rem',fontWeight:700}}>
                    Observación
                </Typography>
                <Typography variant="body2" >
                    {seleccionado.Observ}
                </Typography>
                </span>:null}
                </Card>
                
                <Card className={miClase.cardTexto}>
                    <Typography className={miClase.texto}>
                        {seleccionado.Direcc}
                    </Typography>
                </Card>
                
                <Card className={miClase.cardMapa}>
                    <MapaAlerta Seleccionado={seleccionado}/>
                </Card>
                
            </Box>
            
        </ModalInformativo>
    )
}

export default ModalMapa
