import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
      //Paper,Collapse,Button,Card,
    TableContainer,TableHead,TableRow,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; 
import CreateIcon from '@material-ui/icons/Create'; 
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import EditarElemento from './EditarElemento'
import ModalImg from './ModalImg'

const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    
    {
      id:5,
      titulo:"Elemento",                                                
      alinear:'center'
    },
    {
      id:15,
      titulo:"Folio",                                                
      alinear:'center'
    },
    {
      id:14,
      titulo:"Placa",                                                
      alinear:'center'
    },
    {
      id:16,
      titulo:"Tipo",                                                
      alinear:'center'
    }, 
    {
      id:12,
      titulo:"CURP",                                                
      alinear:'center'
    },
    {
      id:7,
      titulo:"INE",                                                
      alinear:'center'
    }, 
    /* {
      id:11,
      titulo:"RFC",                                                
      alinear:'center'
    },    */
    {
      id:13,
      titulo:"Telefono",                                                
      alinear:'center'
    },   
        
    {
      id:8,
      titulo:"Email",                                                
      alinear:'center'
    },
    {
      id:9,
      titulo:"Edad",                                                
      alinear:'center'
    },
    {
      id:6,
      titulo:"Genero",                                                
      alinear:'center'
    },
     
    {
      id:10,
      titulo:"F. Reg.",                                                
      alinear:'center'
    },        
  ];
  
const TablaElementos = ({auxNombre,auxActualizar,setActualizar}) => {
  const classes = useStyles();
  const [datos, guardarDatos] = React.useState([])  
  const[sinDatos,guardarSinDatos]= React.useState(true)
  const [espera,setEspera]=React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);  
  const [titulos, setTitulos] = React.useState({tituloEditElem:'',tituloImg:''});
  const [clienteSeleccionado,setClienteSeleccionado]= useState([]);   
  const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false);   
  const [modalImgAbierto,setModalImgAbierto]= useState(false);   
  let history = useHistory();     
  const{tituloEditElem,tituloImg}=titulos
    
  React.useEffect(()=>
  {
    const source = axios.CancelToken.source();    
    async function conectarJSon()  {       
      setEspera(true) ;
      
      let auxU= process.env.REACT_APP_LINKTEZO +`/usrseg-list`;               
      let token =localStorage.getItem('token20') ;               
      let dataInfo = qs.stringify({                                 
          'usuario':1,
          'cadena':auxNombre,
          'idDispositivo':'ifrekvjkxcdwdcwe'
          });
      let config = {
          url:auxU ,
          method: 'POST',
          headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },          
          data: dataInfo,
          cancelToken: source.token
      };
      let aux ={};           
      await axios(config)
      .then(function (response) {                
        aux=  response.data;      
        //console.log(aux);                     
        if (aux[0].Id != -1) 
        { // console.log(aux);  
          guardarSinDatos(true)                                                           
          guardarDatos(aux)
        }
        else
        { 
          guardarSinDatos(false)       
        }                                            
      })
      .catch(function (error) {                
        console.log(error);        
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
          console.log('datos incorrectos' );  
        }else
        {
          if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
              ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
          {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
          } 
        }
      });              
      setEspera(false)  ;
    };     
      
    conectarJSon()

    return ()=> {
      source.cancel();
    }

  },[auxActualizar])

  const seleccionado=(auxId,auxReg)=>{
    setSelectedID(auxId)       
  }
   
  function abrirModalEditCliente(auxClient)
  { setClienteSeleccionado(auxClient)
    setTitulos({...titulos,
      tituloEditElem:'Editar '+auxClient.SegNom+' '+auxClient.SegPat+' '+auxClient.SegMat
    })    
    setModalEditCliAbierto(true);    
  }     
  
  function abrirModalImg(auxClient)
  { setClienteSeleccionado(auxClient)
    setTitulos({...titulos,
      tituloImg:'Elemento '+auxClient.SegNom+' '+auxClient.SegPat+' '+auxClient.SegMat
    })    
    setModalImgAbierto(true);    
  }

  function TablaColumReg(datoRegis,alinear,estilos){
    return(
    <TableCell className={classes.tableCell} padding={'none'} align={alinear} style={estilos} >
      {datoRegis}
    </TableCell>
    )
  }

  function getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    //let fechaNacimiento = new Date("1990-03-26T00:00:00.000Z")    
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if ( diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) 
    {
      edad--
    }
    return edad
  }

  function Renglon(props)
  {
    const { row } = props;    
    let auxFecha=(moment.utc( row.UsrFreg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" 
                  : moment.utc( row.UsrFreg).format('DD/MM/YYYY HH:mm')  )
    return (
    <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row.SegId,row); } }
      className={classes.tableRow} selected={ selectedID === row.SegId}      
      classes={{ hover: classes.hover, selected: classes.selected }}        
    > 
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Editar Elemento`}>
        <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
          {selectedID === row.SegId ? 
            <CreateIcon style={{color:'white',width:18+'px'}} />
            :<CreateIcon style={{width:18+'px'}} color="primary"/>
          }
        </IconButton>                            
        </Tooltip>                                                                                                                                              
      </TableCell>  
      <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
        <Tooltip title={`Fotografia del Elemento`}>
        <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalImg(row)} component="span">
          {selectedID === row.SegId ? 
            <img src='./marcadores/CAMARA_ICONO_white.svg' style={{width:20+'px'}}/>
            :<img src='./marcadores/CAMARA_ICONO.svg'style={{width:20+'px'}}/>
          }
        </IconButton>                            
        </Tooltip>                                                                                                                                              
      </TableCell>             
      {/* {TablaColumReg(row.SegId,"center",{ fontWeight:700,width:40+'px', paddingRight:2+'px'} ) }      */}
      {TablaColumReg(row.SegNom+' '+row.SegPat+' '+row.SegMat,"left",{  width:250+'px',fontSize:12+'px',paddingLeft:.5+'rem'} ) }             
      {TablaColumReg(row.SegFolio, "center", { width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }             
      {TablaColumReg(row.SegPlaca, "center", { width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }             
      {TablaColumReg(row.TipoNom, "center", { width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }  
      {TablaColumReg(row.SegCurp, "center", { width:150+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }             
      {TablaColumReg(row.SegIne, "center", { width:150+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }  
      {/* {TablaColumReg(row.Rfc, "center", { width:90+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }   */}
      {TablaColumReg(row.SegTel, "center", { width:60+'px',paddingLeft:.5+'rem',fontSize:12+'px'} ) }        
      {TablaColumReg(row.UsrEmail, "left", { width:120+'px',fontSize:12+'px',paddingLeft:8+'px'} ) }  
      {TablaColumReg(getEdad(row.SegNac), "center", { width:30+'px',fontSize:12+'px',paddingLeft:5+'px'} ) }  
      {TablaColumReg(row.SegGenero, "center", { width:40+'px',} ) }  
      {TablaColumReg(auxFecha, "center", { width:105+'px',fontSize:12+'px',paddingLeft:8+'px'} ) }                                   
    </TableRow>        
    </React.Fragment>
  );}

  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
    let colorLetra="#0F82EE"
    return(
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>       
          <TableCell align="center" padding={'none'} style={{ color:colorLetra}}  >            
          </TableCell>                               
          <TableCell align="center" padding={'none'} style={{ color:colorLetra}}  >
            {auxlista.length}
          </TableCell>                                   
          {columnas.map(row => {              
            return(  
              <TableCell style={{ color:colorLetra}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
          <Renglon key={row.Id} row={row} />
        ))) :null }
      </TableBody>
    </Table>
    </TableContainer>
    )
  }

  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:'#0F82EE',color:'white'}} 
              key={row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Elementos</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )
  }
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modEditCli = modalEditCliAbierto ? 
                    ( <EditarElemento modalAbierto={modalEditCliAbierto}  
                        setModalAbierto={setModalEditCliAbierto} usrSelecc={clienteSeleccionado}
                        titulo={tituloEditElem} setActualizar={setActualizar} actualizar={auxActualizar}
                      />
                    ): null;       
  const modImg = modalImgAbierto ? 
                  ( <ModalImg modalAbierto={modalImgAbierto}  
                      setModalAbierto={setModalImgAbierto} seleccionado={clienteSeleccionado}
                      titulo={tituloImg} setActualizar={setActualizar} actualizar={auxActualizar}
                    />
                  ): null;                                                                                                                                
  return (
  <div style={{minWidth:1215+'px',marginTop:.5+'rem'}}>        
    {tabla}   
    {modImg} 
    {modEditCli}
  </div>
  )
}

export default TablaElementos
