import React, {useState, useEffect} from 'react';
import {Table, TableCell, TableContainer, TableRow, TableHead, TableBody,Card,
          Tooltip, IconButton,Box,LinearProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTimer } from 'use-timer'
import CreateIcon from '@material-ui/icons/Create';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import MenuAlerta from './MenuAlerta';
import EditarAlerta from './EditarAlerta'
import { useHistory } from "react-router-dom";
import EditarBitacora from './NuevaAlertaBitacora'
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    },
    container2: {
    maxWidth:'100%',
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#0F91C1"
        }
      },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected:{}
});
  const columnas=[          
    { id:1, titulo:"F. Registro", alinear:'center' },
    { id:2, titulo:"F. Atención", alinear:'center' },
    { id:9, titulo:"Tipo", alinear:'center' },
    { id:10, titulo:"Status", alinear:'center' },  
    { id:4, titulo:"Nombre", alinear:'center' },
    { id:5, titulo:"Elemento Seguridad", alinear:'center' },  
    /*  { id:6, titulo:"No. Unidad", alinear:'center' }, */
    { id:7, titulo:"Placa", alinear:'center' },
    { id:8, titulo:'Categoría', alinear:'center' },      
    /* { id:11, titulo:"Latitud", alinear:'center' },      
    { id:12, titulo:"Longitud", alinear:'center' },   */
    
  ];
export default function TablaNotificacion({buscar,fechasSeleccionadas,actualizar,conta}){
  const { time, start, pause, reset } = useTimer({ interval : 1000,  initialTime : 0,  });
  
  const valorInicial = { mouseX: null, mouseY: null, };
  let history = useHistory();   
  const classes = useStyles();
  const [espera,setEspera] = useState(true)
  const [resultados,setResultados] = useState([])
  const [selectedID, setSelectedID] = useState(null);
  const [modalEditar, setModalEditar] = useState(false);
  const [tituloEditar, setTituloEditar] = useState("")
  const [subtEditar, setSubtEditar] = useState("")
  const [seleccionado,setSeleccionado] = useState(null)
  const [menuSeleccionado, setMenuSeleccionado] = useState({})
  const [valorCursor, setValorCursor] = useState(valorInicial);
  const [tituloNuevo, setTituloNuevo] = useState("")
  const [modalNvaBitacora, setModalNvaBitacora] = useState(false)
  const [subtInfoBit, setSubtInfoBit] = useState("")
  const source = axios.CancelToken.source();    
  useEffect(()=>{          
      
    listaIntervalo();
    start();    

  },[buscar])

  useEffect(()=>{
    if(actualizar)
    {
      reset()
      start()
    }
    else
    {  listaIntervalo2();
      
    }
  },[actualizar])

  const listaIntervalo = () => {
    const fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DD");    
    const data = qs.stringify({
      FIni:fInicio+'T00:00:00',
      FFin:fFinal+'T23:59:59',       
      idDispositivo:'w845sdc15ds3dc1s'
    });
    const url = "/alerta-list";

    function respuesta(aux) {
      if(aux[0].Id>-1)
      {
        setResultados(aux)
       // console.log(aux)
      }else{
        setResultados([])
      }
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
  };

  const listaIntervalo2 = () => {
    const fInicio=moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fechasSeleccionadas.final).format("YYYY-MM-DD");    
    const data = qs.stringify({
      FIni:fInicio+'T00:00:00',
      FFin:fFinal+'T23:59:59',       
      idDispositivo:'w845sdc15ds3dc1s'
    });
    const url = "/alerta-list";

    function respuesta(aux) {
      if(aux[0].Id>-1)
      {
        setResultados(aux)
        let filtrado=aux.filter((registro)=>registro.Id===menuSeleccionado.Id)
        if (filtrado.length!==0) 
        {
          setMenuSeleccionado(filtrado[0])  
          let titulo=`Actualizar Estatus de Bitácora ${filtrado[0].TipoNom} ${filtrado[0].Id}`;
          setTituloNuevo(titulo);
        }
        
       // console.log(aux)
      }
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
    //llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
  };

  const cerrarMenu = () => {
    setValorCursor(valorInicial);
  }
    
  function guadarPosCursor(event,registro) {
    event.preventDefault();
    setValorCursor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setMenuSeleccionado(registro)
    setSelectedID(registro.Id)
    let date = new Date();
    let titulo=`Actualizar Estatus de Bitácora ${registro.TipoNom} ${registro.Id}`;
    setTituloNuevo(titulo);
    setSubtInfoBit(moment.utc(date).local(true).format("DD/MM/YYYY HH:mm"))
  }

  function actualizarRes(){
    setResultados([]);
    listaIntervalo();
    reset();
    start();
  }

  /* function abrirModalEditar(reg){
    setSeleccionado(reg)
    let date = new Date();
    let titulo=`Editar ${reg.TipoNom} ${reg.Id}`;
    setTituloEditar(titulo)
    setSubtEditar(moment.utc(date).local(true).format("DD/MM/YYYY"))
    setModalEditar(!modalEditar)
  } */

  function colorEstatus(colorEstado,valor)
  { 
    let auxColor=colorEstado
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.5+'rem'}} 
      >
        <Card style={{color:'white', fontWeight:"bold", backgroundColor:auxColor}}>
          {valor}
        </Card>
      </TableCell>    )
    return renglon
  }

  function colorTipo(colotTipo,valor)
  { 
    let auxColor=colotTipo
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:160+'px',paddingLeft:.5+'rem'}} 
      >
        <Card style={{color:'white', fontWeight:"bold", backgroundColor:auxColor}}>
          {valor}
        </Card>
      </TableCell>)
    return renglon
  }

  function seleccionarId(auxId){
    setSelectedID(auxId)
  }

  //Descomentar el tableCell del ícono para ver la sección de editar, si no se pierde la dimensión de tabla
  function Renglon(props) {
    const { row } = props;  
    return(
    <TableRow onClick={ () => { seleccionarId(row.Id); } }
      className={classes.tableRow} selected={ selectedID === row.Id}
      onContextMenu={(event)=>guadarPosCursor(event,row)} 
      classes={{ hover: classes.hover, selected: classes.selected }}
    >
      <TableCell className={classes.tableCell} align="center" padding={'none'} style={{ width:20+'px',fontSize:11+'px'}}>
        <b>{row.Id}</b>
      </TableCell>
      <TableCell className={classes.tableCell} align="center" padding={'none'} style={{ width:110+'px',fontSize:11+'px'}}>
          {moment.utc( row.FchReg).format('YYYY-MM-DD HH:mm')=== "1900-01-01 00:00"
          ? '' : moment.utc( row.FchReg).format('DD/MM/YYYY HH:mm')}
      </TableCell>
      <TableCell className={classes.tableCell} align="center" padding={'none'} style={{ width:110+'px',fontSize:11+'px',paddingLeft:.2+'rem'}}>
          {moment.utc( row.FchAtencion).format('YYYY-MM-DD HH:mm')=== "1900-01-01 00:00"
          ? '' : moment.utc( row.FchAtencion).format('DD/MM/YYYY HH:mm')}
      </TableCell>
      {colorTipo(row.TipoColor,row.TipoNom)}
      {colorEstatus(row.StatusColor,row.StatusNom)}
      <TableCell className={classes.tableCell} align="center" padding={'none'} style={{ width:250+'px',fontSize:12+'px',paddingLeft:.5+'rem'}}>
          {row.PublicoNom}
      </TableCell>
      <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:250+'px',fontSize:12+'px',paddingLeft:.5+'rem'}}>
          {row.SegElemNom}
      </TableCell>
      {/*
      <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:150+'px',fontSize:12+'px',paddingLeft:.5+'rem'}}>
          {row.SegUnidadNumEco ? row.SegUnidadNumEco : ""}
      </TableCell>
      */}
      
      <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:80+'px',fontSize:13+'px',paddingLeft:.5+'rem'}}>
          {row.SegUnidadPlaca ? row.SegUnidadPlaca : ""}
      </TableCell>
      <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:150+'px',fontSize:14+'px',paddingLeft:.5+'rem'}}>
          {row.CategNom}
      </TableCell>
      {/* <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:60+'px',fontSize:13+'px',paddingLeft:.5+'rem'}}>
          {row.Lat}
      </TableCell>
      <TableCell className={classes.tableCell}  align="center" padding={'none'} style={{ width:60+'px',fontSize:13+'px',paddingLeft:.5+'rem'}}>
          {row.Lon}
      </TableCell> */}
    </TableRow>
    )        
  }

      //Descomentar el tableCell para ver la sección de editar, si no se pierde la dimensión de tabla
    function generarTabla(){
      return(
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>    
            {/* 
            <TableCell align="center" padding={'none'} style={{ width:8+'px', color:'#0F82EE'}} >                                                                                                                                                      
              </TableCell> 
            */}
            <TableCell align="center" padding={'none'} style={{ width:10+'px', color:'#0F82EE'}} >
            <b>{resultados.length}</b>                                                                                                                                                          
            </TableCell>  
            {columnas.map(row => {              
              return(  
              <TableCell style={{ color:'#0F82EE',fontWeight:600}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}   
          </TableRow>
        </TableHead>
        <TableBody>
          {resultados.length!=0 ? (resultados.map((row) => (
              <Renglon key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
      </TableContainer>
      )
    }

    function sinResultados(){
      return(
      <TableContainer className={classes.container2}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>     
            <TableCell align="center" padding={'none'} style={{ width:10+'px', color:'#0F82EE'}} >
                  <b>{0}</b>                                                                                                                                                          
              </TableCell>         
              {columnas.map(row => {              
                return(  
                <TableCell style={{ color:'#0F82EE',fontWeight:600}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
                )                
              })}                                                
            </TableRow>
        </TableHead>
        <TableBody>
          <TableRow  className={classes.tableRow}> 
          <TableCell  className={classes.tableCell} align="center" style={{ width:20+'px',fontSize:11+'px',paddingLeft:.5+'rem'}}> </TableCell>
            <TableCell  className={classes.tableCell} align="center" >Sin </TableCell>                    
            <TableCell  className={classes.tableCell} align="center" ></TableCell>
            <TableCell  className={classes.tableCell} align="center" >Alertas</TableCell>    
            <TableCell  className={classes.tableCell} align="center" ></TableCell>
            <TableCell  className={classes.tableCell} align="center">Registradas</TableCell>     
            <TableCell  className={classes.tableCell} align="center"></TableCell> 
      {/*        <TableCell  className={classes.tableCell} align="center" style={{ width:80+'px',fontSize:11+'px',paddingLeft:.5+'rem'}}></TableCell>   
            <TableCell  className={classes.tableCell} align="center"></TableCell>    */}                 
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>
      )
    }
  const menuAL = valorCursor.mouseY !== null ? <MenuAlerta valorCursor={valorCursor} handleClose={cerrarMenu}
                                                alertaSeleccionada={menuSeleccionado} setModalNvaBitacora={setModalNvaBitacora}
                                              />
                                              :null

  const modEdit = modalEditar? <EditarAlerta alerta={seleccionado} modalAbierto={modalEditar} setModalAbierto={setModalEditar}
                                titulo={tituloEditar} subtitulo={subtEditar}/>
                              :null
  const modalNuevo = modalNvaBitacora ? <EditarBitacora registro={menuSeleccionado} titulo={tituloNuevo} subtitulo={subtInfoBit}
                                        setModalAbierto={setModalNvaBitacora} modalAbierto={modalNvaBitacora}
                                        setFuncion={listaIntervalo2}
                                      /> 
                                      : null;                                                  
  const contenido = resultados.length!==0 ? generarTabla() : sinResultados();
  const cargando = espera ? <Box><LinearProgress/></Box> : contenido;

  return(
  <>
   {/*  <div>{time}</div> */}
    {cargando}
    {menuAL}
    {modEdit}
    {modalNuevo}
    {time === 90 && actualizar ? actualizarRes() : null}
  </>
  )
}