import React,{useState,useEffect} from 'react'
import {List,ListItem,Collapse,Typography,LinearProgress,
    ListItemText,Divider,Box} from "@material-ui/core";
import {ExpandLess,ExpandMore  } from '@material-ui/icons';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MapaAlerta from './MapaAlerta'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from "socket.io-client";

const ENDPOINT = "https://sockets.daduva.mx:5000";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      
    },
    nested: {
      paddingLeft: theme.spacing(4),
      
    },
  }));
  let auxArreglo=[[
    {
        "lat": 19.275282,
        "lng": -99.6714263
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752817,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752611,
        "lng": -99.6714363
    },
    {
        "lat": 19.2752528,
        "lng": -99.6714556
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.2753196,
        "lng": -99.671415
    },
    {
        "lat": 19.27532,
        "lng": -99.671415
    },
    {
        "lat": 19.2752772,
        "lng": -99.6714264
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752577,
        "lng": -99.6714624
    },
    {
        "lat": 19.275276,
        "lng": -99.6714308
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2752783,
        "lng": -99.6714267
    },
    {
        "lat": 19.2753624,
        "lng": -99.6714291
    }
]]
const Monitoreo = () => {
    const source = axios.CancelToken.source();    
    let history = useHistory();   
    const classes = useStyles();
    const[listObjeto,setListObjeto]=useState([])
    const[listCoodTipo,setListCoodTipo]=useState([])
    const[listSectorMap,setListSectorMap]=useState([])
    const[listObjetos,setListObjetos]=useState([])
    const[listCuadrante,setListCuadrante]=useState([])
    const[listGpsUnidad,setListGpsUnidad]=useState([])
    const[listGpsUnidad2,setListGpsUnidad2]=useState([])
    const[listGpsUnidSeg,setListGpsUnidSeg]=useState([])
    const[limpiarUnid,setLimpiarUnid]=useState(false)
    const libreriaG=[process.env.REACT_APP_GOOGLE_LIBRARIE]

    useEffect(()=>{
        let token =localStorage.getItem('token20') ;    
        let listObj =localStorage.getItem('listObj20') ;    
        let listSect =localStorage.getItem('listSector20') ;   
        let listCuad =localStorage.getItem('listCuad20') ;    
        async function llamaListCoordTipo(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                { let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCoodTipo(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListObjeto(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListObjeto(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListSectorMap(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-sector-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,                  
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    //let nvoFilt=[]
                   /*  aux.forEach(sector => {
                        nvoFilt.push({Lat,Lon})
                    }); */
                    localStorage.setItem('listSector20',JSON.stringify(filtrado));  
                    setListSectorMap(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListCuadrante(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,   
                    'tipo':0,               
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCuadrante(filtrado)
                    localStorage.setItem('listCuad20',JSON.stringify(filtrado));  
                    /* function prbC(){
                        console.log("dentro del toast")
                    }                                        
                    let mensaje="cargando datos"
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `prb-map01`,
                        onClick:()=>prbC()
                    }) */
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListObjetos(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,        
                    'tipo':0,          
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)             
                    setListObjetos(filtrado)
                    localStorage.setItem('listObj20',JSON.stringify(filtrado)); 
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        if (listObj===undefined || listObj===null) //existen las coordenadas de la lista de objetos
        {   //console.log("saltando filtro");      
            llamaListObjetos()   
        }
        else
        {   let auxList=JSON.parse(listObj)                 
            //console.log(auxList);
            //generaCoordObj(auxList)
            setListObjetos(auxList) 
        }
        if (listSect===undefined || listSect===null) //existen las coordenadas de la lista de sectores
        {   //console.log("saltando filtro2");      
            llamaListSectorMap()  
        }
        else
        {   let auxList=JSON.parse(listSect)                 
            //console.log(auxList);
            setListSectorMap(auxList) 
        }
        if (listCuad===undefined || listCuad===null) //existen las coordenadas de la lista de cuadrantes
        {   //console.log("saltando filtro3");      
            llamaListCuadrante()
        }
        else
        {   let auxList=JSON.parse(listCuad)                 
            //console.log(auxList);
            setListCuadrante(auxList) 
        }        
        llamaListObjeto()
        llamaListCoordTipo()                
    },[])    

    useEffect(()=>{    
        const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta 

        socket.on("GPSUnidadActual", (GPSSocket) => {                  
            //console.log(JSON.parse(GPSSocket));            
            const auxGps=JSON.parse(GPSSocket)            
            setListGpsUnidad2([auxGps,...listGpsUnidad2])
             if (auxGps.Lat!==0 && auxGps.Lon !==0) 
            {   let nvoRegistro=[]
               //console.log(auxGps);
                const aux1 =generaCoordenadaDinam(auxGps.Lat ,auxGps.Lon)
                if (listGpsUnidad.length!==0) // verifica que tenga unidades registradas y filtra las que tienen actualizaciones
                {   const unidad=listGpsUnidSeg.filter((gpUnidad)=> gpUnidad.usuPatrulla === auxGps.UnidId)         
                    const filtrado=listGpsUnidad.filter((gpUnidad)=> gpUnidad.usuPatrulla !== auxGps.UnidId)                    
                    const filtroSeg=listGpsUnidSeg.filter((gpUnidad)=> gpUnidad.usuPatrulla !== auxGps.UnidId)     
                    //console.log(unidad,auxGps.UnidId, listGpsUnidSeg);           
                    filtrado.push({coordenada:aux1, usuPatrulla:auxGps.UnidId ,fecha:auxGps.fecha})
                    if (unidad.length!==0) {                                                                     
                        
                        let arrCoord=unidad[0].coordenadas 
                        //console.log(arrCoord);
                        arrCoord.push(aux1)                           
                        filtroSeg.push({coordenadas:arrCoord, usuPatrulla:auxGps.UnidId,fecha:auxGps.fecha })   
                        
                        //nvoRegistro=filtrado                                                          
                        setListGpsUnidSeg(filtroSeg)
                        //setLimpiarUnid(false)                        
                    }else{                                                                    
                        
                        //let filtroSeg=[]                          
                        let arrCoord=[]
                        arrCoord.push(aux1)
                        filtroSeg.push({coordenadas:arrCoord, usuPatrulla:auxGps.UnidId,fecha:auxGps.fecha })                        
                        setListGpsUnidSeg(filtroSeg)                        
                        //setListGpsUnidad(filtrado)
                    }
                    setListGpsUnidad(filtrado)
                }                
                else
                {    nvoRegistro.push({coordenada:aux1, usuPatrulla:auxGps.UnidId ,fecha:auxGps.fecha})                    
                    setListGpsUnidad(nvoRegistro)
                    let filtroSeg=[]                          
                    let arrCoord=[]
                    arrCoord.push(aux1)
                    filtroSeg[0]={coordenadas:arrCoord, usuPatrulla:auxGps.UnidId,fecha:auxGps.fecha }
                    //console.log(filtroSeg); 
                    setListGpsUnidSeg(filtroSeg)
                    //setLimpiarUnid(false)
                }
                setLimpiarUnid(false)
            }
        });   

        return () => socket.disconnect();     
    })  

 
    function nueva(nvl,tipo) {
        console.log(nvl);
        console.log(tipo);
    }

    const generaCoordenadaDinam=(auxLat,auxLng)=>{
        let coordenada=[]
        let aux1=auxLat;            
        aux1=parseFloat(aux1)
        let aux2=auxLng;            
        aux2=parseFloat(aux2)        
        coordenada={ lat : aux1, lng :aux2 }
      return coordenada  
      }
/*     function ListadoItem(nombre,tipo) {
        return(
        <ListItem button className={classes.nested}
            onClick={()=>nueva(nombre,tipo)} 
        >                    
            <ListItemText    
                secondary={
                    <Typography variant="body2" style={{ color: 'white' }}>
                        {nombre}
                    </Typography>
                } 
            />
        </ListItem> 
        )    
    }
    function generaLista() {
        return(
        <List component='nav'>                
            <ListItem button onClick={()=>cambiar(1)}>                    
                <ListItemText primary="Menu" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div"  disablePadding>    
                    { ListadoItem('Alertas','/bitacora') }  
                    { ListadoItem('Elementos','/lista-elemento') }                    
                    { ListadoItem('Usuarios','/lista-usuario') }    
                    { ListadoItem('Mapa','/monitoreo-map') }                                                                                                                                                                       
                </List>
            </Collapse>
        <Divider/> 
        <ListItem button onClick={contacto} >                    
            <ListItemText  
                secondary={<Typography variant="body2" style={{ color: 'white' }}>
                Contacto</Typography>}
            />                    
        </ListItem>
        </List>
        )
    } */
    let altuTamMax =800
    const   tamanioH= (window.innerHeight<altuTamMax ? window.innerHeight-100: window.innerHeight - 100)
    return (
    <div>
        <Box width={100+'%'} >
            {  listSectorMap.length!==0  && listObjetos.length!==0 && listCuadrante.length!==0?
                <MapaAlerta  Seleccionado={listSectorMap}  tamanio={{height: tamanioH, width: "100%"}}
                    listObjetos={ listObjetos  } listCuadrante={listCuadrante} listGpsUnidad={listGpsUnidad} 
                    listGpsUnidSeg={listGpsUnidSeg} setListGpsUnidSeg={setListGpsUnidSeg}
                    limpiarUnid={limpiarUnid} setLimpiarUnid={setLimpiarUnid} libreriaG={libreriaG}
                />
            :<Box mt={5}><LinearProgress/></Box>   }
        </Box>
    </div>
    )
}

export default Monitoreo