import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify';
import {generarTema} from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import Contenedor from './componentes/layout/Contenedor'
//import RedEmpInicio from './componentes/redEmpInicio/RedEmpInicio'
//import RedEmpBuscar from './componentes/redEmpBuscar/Buscar'
//import RedEmpTrabajo from './componentes/redEmpTrabajo/BusqTrabajoInicio'
import Login from './componentes/auth/Login'
import ListadoUsu from './componentes/usuario/UsuarioInicio'
import NotificacionInicio from './componentes/notificacion/NotificacionInicio';
import ListaElemento from './componentes/elemento/ElementoInicio'
import Monitoreo from './componentes/MonitoreoMaps/Monitoreo';
import MonitoreoFiltro from './componentes/MonitoreoMaps/MonitoreoFiltro';
import MonitUnidSeg from './componentes/MonitoreoMaps/MonitUnidSeg';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [colorTema,setColorTema] = React.useState(false);
  React.useEffect(()=>{
    const auxColor= localStorage.getItem('ColorTheme');
    if ( auxColor===undefined || auxColor===null) 
    {
      localStorage.setItem('ColorTheme',colorTema);
      
    }
    else
    { 
      setColorTema(JSON.parse(auxColor))    
    }
  },[])

  return (
  <React.Fragment >
    <AuthProvider>
      <GeneralesProvider>   
        <Router>
          <ThemeProvider theme={generarTema(colorTema)}>
            <Contenedor colorTema={colorTema} setColorTema={setColorTema}>              
              <Switch>
                <Route exact path="/" component={Login}  />                          
                <Route exact path="/lista-usuario" component={ListadoUsu}  />   
                <Route exact path="/lista-elemento" component={ListaElemento}  />   
                <Route exact path="/bitacora" component={NotificacionInicio}  /> 
                <Route exact path="/monitoreo-map" component={Monitoreo}  /> 
                <Route exact path="/monitoreo-map-filtro" component={MonitoreoFiltro}  /> 
                <Route exact path="/monitoreo-unidad-seguimiento" component={MonitUnidSeg}  /> 
                <Route path="*" component={  Login } />                                   
                {/* <Route exact path="/red-emp-inicio" component={RedEmpInicio}  />  
                <Route exact path="/red-emp-busq" component={RedEmpBuscar}  />     
                <Route exact path="/red-emp-trabajo" component={RedEmpTrabajo}  />   */}
                
              </Switch>              
            </Contenedor>
          </ThemeProvider>
          <ToastContainer autoClose={5000} closeOnClick/>
        </Router> 
      </GeneralesProvider>
    </AuthProvider>
  </React.Fragment>
  );
}

export default App;