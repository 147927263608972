import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions,
        DialogContent, DialogTitle, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    alignItems: 'center',
    width: '20rem'
  },
  wrapper: {    
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ModalGeneral({titulo,subtitulo,children,modalAbierto,setModalAbierto,
                                      guardarDatos,mensaje, tipoAdvertencia,
                                      aviso, setAviso, tamanio,btnDeshabilitado,cargando}) {
  //const [open, setOpen] = React.useState(false);  
  const [scroll, setScroll] = useState('paper');      

  const cerrarBorrar = () => {    
    //setOpen(false);
    setAviso(false);
    setModalAbierto(false);
  };
  const handleClose = () => {
  //  setOpen(false);
    setModalAbierto(false)
  };

  const handleCloseSN = () => {
    setAviso(false);    
  };  
  const classes = useStyles();    
  const descriptionElementRef = React.useRef(null);
  
  useEffect(() => {
      if (modalAbierto) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      } 
  }, [modalAbierto]);
  
  return (
  <div>         
    <Dialog
      open={modalAbierto}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="titulo"
      aria-describedby="descripcion"
      fullWidth={false}
      maxWidth={tamanio}
    >
      <DialogTitle style={{color:'#0F82EE'}} id="titulo" align="center" >
        {titulo}
        {subtitulo ? <div style={{color:'black', fontSize:17}}>{subtitulo}</div> : null}
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >        
        { aviso && tipoAdvertencia && mensaje.length!==0 ? 
            <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>
          :   <span></span>
        }

        {children}        
      </DialogContent>
  
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={cargando} color="secondary"  variant="outlined">
            Cerrar
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="outlined"   className={classes.buttonGuardar}
            disabled={btnDeshabilitado ? true: cargando}
            onClick={guardarDatos}
          >
            Guardar
          </Button>
          {cargando && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>                    
      </DialogActions>
    </Dialog>    
  </div>
  );
}