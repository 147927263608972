import React, {useState, useEffect} from 'react';
import axios from "axios";
import qs from "qs";
import ModalInfo from '../generales/ModalInformativo'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { IconButton, LinearProgress, Box , List, Card,Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();


const useStyles = makeStyles((theme) => ({
  
    alturaLista:{
        minHeight: 180,
        maxHeight: window.innerHeight-350,
        overflow: 'auto',
        alignContent:'center',
    },
    estiloCards:{
      marginBottom:'.3em',
    },
    tamanioFecha:{
      height:9,
      fontSize:12,
      marginTop:5,
      width:"auto"
    },
      tamanioLog:{
        marginLeft:'.4em',
        fontSize:12,
        marginTop:5
    },
    tamanioCard:{
        position:'relative',
        marginLeft:'.4em',
        width:75,
        fontSize:12,
        fontWeight:'bold',
        marginTop:3,
        color:'white',
        height:20
    },

    stat:{
      position: "absolute",
      top: '55%',
      transform: 'translateY(-55%)'
    }
  }));

  
export default function Bitacora({seleccionado,setModalAbierto,modalAbierto,titulo,subtitulo}){
  const classes = useStyles();
  const [historial,setHistorial] = useState([]);
  const [espera, setEspera] = useState([]);
  let history = useHistory();   
  useEffect(()=>{
    let token =localStorage.getItem('token20') ;    
      async function getBitacora(){
        setEspera(true);
        let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-list-bitacora`;   
        let dataInfo = qs.stringify({                               
            'Alerta':seleccionado.Id,     
            'idDispositivo':'w845sdc15ds3dc1s'
            });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};     
            await axios(config)
            .then(function (response) {          
                aux=  response.data;   
              //  console.log(aux) 
                if(aux[0].Id>-1){
                    setHistorial(aux)
                }
            }).catch(function (error) {          
              if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
              {
                console.log('datos incorrectos' );  
              }
              else
              {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                  ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                  console.log('loguearse de nuevo');
                  localStorage.clear();                      
                  history.push("/");                              
                } 
              }
            })
        setEspera(false)
    }
    getBitacora();

      
  },[])


    function generarStatus(color,valor){
       return( 
        <Card className= {classes.tamanioCard} style={{backgroundColor:color, alignContent:'center'}}>
            <div style={{marginLeft:2}} className={classes.stat}>
                  {valor}</div>
      </Card>)
    }
    function generarBitacora(){
        let arrayElementos =[];
        historial.map((bitacora)=>{
            arrayElementos.push(
            <Card className={classes.estiloCards} key={bitacora.Id}> 
              <Box display="flex" flexDirection="row">
                <Box display="flex" flexDirection="row">
                <AccessTimeIcon style={{color:'#0F82EE', width:'.6em', marginRight:3,marginTop:1.2,marginLeft:4}}/>
                <Typography className={classes.tamanioFecha}>
                  <b>{moment.utc(bitacora.FchReg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                      ? "" : moment.utc(bitacora.FchReg).format('DD/MM/YYYY HH:mm')}</b>
                </Typography>
                {bitacora.StatusId ===3 || bitacora.StatusId === 4 ?
                <Typography className={classes.tamanioLog}>
                    {moment.utc(bitacora.FchCierre).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                       ? "" : "Cierre:"} 
                </Typography>
                :null}

                {bitacora.StatusId ===3 || bitacora.StatusId === 4 ?
                 <Typography className={classes.tamanioFecha}>
                 <b>{moment.utc(bitacora.FchCierre).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
                                       ? "" : moment.utc(bitacora.FchCierre).format('DD/MM/YYYY HH:mm')}</b>
                 </Typography>
                :null}
                {generarStatus(bitacora.StatusColor, bitacora.StatusNom)}
                <Typography className={classes.tamanioLog}>
                  {bitacora.Direcc}
                </Typography>
                </Box>
                <Box display="flex"className={classes.tamanioLog} style={{fontWeight:'bold',width:"500px"}}>
                  {bitacora.Observ ? bitacora.Observ : "Observación no disponible"}
                </Box>
                
              </Box>
            </Card>)      
        })
        return arrayElementos;
    }

    const mostrarBitacora = historial.length>0 ? generarBitacora() : <p>Sin resultados</p>;
    const listado = espera ?  (<Box><LinearProgress/> </Box>) :( mostrarBitacora )
    return (
        <ModalInfo setModalAbierto={setModalAbierto}
            modalAbierto={modalAbierto} titulo={titulo}
            tamanio='l' colorFondo='#f5f5f5' subtitulo={subtitulo}
        >   
          <Card>
          <Typography variant="body2"  color="primary" style={{marginLeft:0.5+'rem',marginRight:0.5+'rem',fontWeight:700}}>
            Descripción
          </Typography>
          <Typography variant="body2" >
            {seleccionado.Descr}
          </Typography>
          
          {seleccionado.Observ &&seleccionado.Observ.length!==0 ?
          <span>
            <br/>
          <Typography variant="body2" color="primary" style={{marginLeft:0.5+'rem',marginRight:0.5+'rem',fontWeight:700}}>
            Observación
          </Typography>
          <Typography variant="body2" >
            {seleccionado.Observ}
          </Typography>
          </span>:null}
        </Card>
            <List className={classes.alturaLista} style={{width:"100%"}}>
            {listado}
            </List>
        </ModalInfo>
    )
}