import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Button,InputLabel, MenuItem, TextField,Select, Box} from '@material-ui/core'
import { GoogleMap, LoadScript,Autocomplete,Polyline,Polygon, Marker } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'40em'
    },
    formSelect: {
      width:'8em'
    },
    formSelectTipo: {
        width:'180px'
    },
    formSelectElemento:{
        width:'200px'
    },
    formSelectUnidad:{
        width:'180px'
    },
    formDesc:{
        marginTop:'1.1em',
        marginRight:'.2em',
        width:'30em',
        height:'6em'
    },
    formObs:{
        marginTop:'1.1em',
        width:'30em',
        height:'6em'
    },
  }));


  const mapContainerStyle = {
    marginTop:'1.1em',
    height: "300px",
    width: "800px"
  }
  
  const center = {
    lat: 19.592062391482873,  lng:  -98.91206954361832
    //19.592062391482873,-98.91206954361832
  
  }

export default function EditarAlerta({alerta,modalAbierto,setModalAbierto,titulo,subtitulo}){
    const image = { url: "marcador.svg"};
    const image2 = { url:"marcador_seleccionado.svg"  };
    const classes = useStyles();
    const source = axios.CancelToken.source();    
    const [listaElementos, setListaElementos] = useState([])
    const [listaCategoria, setListaCategoria] = useState([])
    const [listaTipo, setListaTipo] = useState([])
    const [listaUnidad, setListaUnidad] = useState([])
    const [espera, setEspera] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [registro, setRegistro] = useState({
                                                  Tipo: alerta.TipoId,
                                                  Categoria: alerta.CategId,
                                                  Unidad: alerta.SegUnidadId,
                                                  Elemento: alerta.SegElemId,
                                                  Lat: alerta.Lat,
                                                  Lon: alerta.Lon,
                                                  Direcc: alerta.Direcc,
                                                  Descr: alerta.Descr,
                                                  Observ: alerta.Observ,
                                                  })
    const {Tipo,Categoria,Unidad,Elemento,Lat,Lon,Direcc,Descr,Observ} = registro
    //const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const [auxMarcador, setAuxMarcador] = useState(center);
    const [centro, setCentro] = useState(center);
    const [autocomplete, setAutocomplete] = useState([]);
    let expNopermitida = new RegExp('[%{}*|`]');  
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');     
    let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]'); 

    useEffect(()=>{
        let token =localStorage.getItem('token20') ;    
        async function getTipoAlerta(){
            
            let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-tipo`;   

            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        
                        setListaTipo(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }

        async function getListaCategoria(){
            let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-categoria`;   
            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        setListaCategoria(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }

        async function getListaUnidad(){
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-unidad-list`;   
            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        aux.sort(function (a, b) { return a.Id - b.Id; });   
                        setListaUnidad(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }

        async function getElementos(){
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-elemento-list`;   
            let dataInfo = qs.stringify({     
                'usuario':1,   
                'idDispositivo':'w845sdc15ds3dc1s'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        aux.sort(function (a, b) { return a.Id - b.Id; });   
                        setListaElementos(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        }
        getTipoAlerta()
        getListaCategoria()
        getListaUnidad()
        getElementos()

        let aux1=Lat;            
        aux1=parseFloat(aux1)
        let aux2=Lon;            
        aux2=parseFloat(aux2)    
        let arreRutas=[]    
        arreRutas={ lat : aux1, lng :aux2 }
        setAuxMarcador(arreRutas)
        setCentro(arreRutas)
    },[]);

    const onLoad =(respuesta)=> {
  
        setAutocomplete(respuesta);
      
      }


    function onChangeTipo(e){
        setRegistro({
            ...registro,
            Tipo: e.target.value
        })
    }

    function onChangeCategoria(e){
        setRegistro({
            ...registro,
            Categoria: e.target.value
        })
    }


    function onChangeUnidad(e){
        setRegistro({
            ...registro,
            Unidad: e.target.value
        })
    }

    function onChangeElemento(e){
        setRegistro({
            ...registro,
            Elemento: e.target.value
        })
    }

    function onChangeDireccion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Direcc: e.target.value
            })
        }
    }

    function onChangeDescripcion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Descr: e.target.value
            })
        }
    }
    function onChangeObservacion(e){
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
        && !expMenos.test(e.target.value) && !expMas.test(e.target.value)) 
        || e.target.value===' '|| e.target.value===''){
            setRegistro({
                ...registro,
                Observ: e.target.value
            })
        }
    }

    function changeMarcador(mark,e){
        console.log(mark)
        console.log(e)
    }

    function verificarTipo(tp){
        let validado=false;
        if(parseInt(tp)!==0){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione un tipo válido")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarCategoria(cat){
        let validado=false;
        if(parseInt(cat)!==0){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione una categoría válida")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarUnidad(uni){
        let validado=false;
        if(parseInt(uni)!==0){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione una unidad válida")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarElemento(ele){
        let validado=false;
        if(parseInt(ele)!==0){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Seleccione un elemento válido")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarObservacion(ob){
        let validado=false;
        if(ob && ob!==" "){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Ingrese Observación")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function verificarDirección(dir){
        let validado=false;
        if(dir && dir!==" "){
            validado=true
        }else{
            setTipoAdvertencia("warning")
            setMensaje("Ingrese Dirección")
            setAviso(true)
            validado=false;
        }
        return validado;
    }

    function handleSubmition(){
        if(verificarTipo(Tipo)&&verificarCategoria(Categoria)&&verificarUnidad(Unidad)
            &&verificarElemento(Elemento)&&verificarDirección(Direcc)&&verificarObservacion(Observ)){
              editarAlerta()
        }
    }

   async function editarAlerta(){
        setEspera(true);
        setBtnDeshabilitado(true);
        let token =localStorage.getItem('token20') ;    
        let auxU=process.env.REACT_APP_LINKTEZO +`/alerta-editar`;   
            let dataInfo = qs.stringify({     
                'Alerta':alerta.Id,
                'Usuario':1,   
                'Usrsystem':1,
                'Tipo':Tipo,
                'Categoria':Categoria,
                'Unidad':Unidad,
                'Elemento': Elemento,
                'Lat': Lat,
                'Lon':Lon,
                'Direcc':Direcc,
                'Descr':Descr,
                'Observ':Observ,
                'idDispositivo':'542d1sc12dsc'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                    //headers: {'Content-Type': 'application/x-www-form-urlencoded' },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux.respuesta===1){
                        
                        setTipoAdvertencia("success")
                        setMensaje("Alerta editada con éxito")
                        setAviso(true)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
        setEspera(false)

    }


    return (
        <Modal
        titulo={titulo}
        subtitulo={subtitulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmition}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'l'}
        cargando={espera}>
            <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                {listaTipo.length!=0 ?
                <Grid item sx={6}>
                <FormControl className={classes.formSelectTipo}>
                    <InputLabel id="Tipo">Tipo</InputLabel>
                    <Select
                      label="Tipo" id="Tipo" name="Tipo"
                      value={Tipo} onChange={onChangeTipo}
                    >
                      {listaTipo.map((tp, index) => (
                        <MenuItem value={tp.Id} key={tp.Id}>
                          <em>{tp.Nom}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                {listaCategoria.length!=0 ? 
                <Grid item sx={1}>
                <FormControl className={classes.formSelect}>
                    <InputLabel id="Categoria">Categoría</InputLabel>
                    <Select
                      label="Categoria" id="Categoria" name="Categoria"
                      value={Categoria} onChange={onChangeCategoria}
                    >
                      {listaCategoria.map((cat, index) => (
                        <MenuItem value={cat.Id} key={cat.Id}>
                          <em>{cat.Nom}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                {listaUnidad.length!=0 ? 
                <Grid item sx={6}>
                <FormControl className={classes.formSelectUnidad}>
                    <InputLabel id="Unidad">Unidad</InputLabel>
                    <Select
                      label="Unidad" id="Unidad" name="Unidad"
                      value={Unidad} onChange={onChangeUnidad}
                    >
                      {listaUnidad.map((uni, index) => (
                        <MenuItem value={uni.Id} key={uni.Id}>
                          <em>{uni.Placa+" "+uni.Observ}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
                {listaElementos.length!=0 ? 
                <Grid item sx={6}>
                <FormControl className={classes.formSelectElemento}>
                    <InputLabel id="Elemento">Elemento</InputLabel>
                    <Select
                      label="Elemento" id="Elemento" name="Elemento"
                      value={Elemento} onChange={onChangeElemento}
                    >
                      {listaElementos.map((ele, index) => (
                        <MenuItem value={ele.Id} key={ele.Id}>
                          <em>{ele.Nombre+" "+ele.Paterno+" "+ele.Materno}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> :null
                }
            </Grid>
            <Grid container spacing={1}>
                <Grid item>
                <FormControl className={classes.formAnotacion}>
                    <TextField required label="Dirección"
                                  value={Direcc} onChange={onChangeDireccion}id={'Direcc'}/> 
                  </FormControl>
                </Grid>
            </Grid>
            <LoadScript googleMapsApiKey="AIzaSyBhYo8BFHYfOSBLQgYVef1_MdWz_y_Z2Mg" libraries={["places"]}> 
                <GoogleMap
                    id="searchbox-example"
                    mapContainerStyle={mapContainerStyle}
                    zoom={14}
                    center={centro}
                >
                    <Marker
                        onClick={(marker,event)=>changeMarcador(marker,event)}
                        draggable={true}
                        onLoad={onLoad}     title={'Ninguno'}
                        position={ auxMarcador} icon= {image}                   
                    />
                </GoogleMap>
            </LoadScript> 
            <Box display="flex" flexDirection="row">
            <textarea className={classes.formDesc} placeholder="Descripción"
              value={Descr} onChange={onChangeDescripcion} id={'Descr'}
              multiline rows={2} maxRows={4}/>
            <textarea className={classes.formObs} placeholder="Observación"
              value={Observ} onChange={onChangeObservacion} id={'Observ'}
              multiline rows={2} maxRows={4}/>
            </Box>
            </form>
        </Modal>
    )
}