import React from 'react'
import {List,ListItem,Collapse,Typography,LinearProgress,
    ListItemText,Divider,Box} from "@material-ui/core";
import {ExpandLess,ExpandMore  } from '@material-ui/icons';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import MapaAlerta from './MapaAlertaFiltro'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from "socket.io-client";

const ENDPOINT = "https://sockets.daduva.mx:5000";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      
    },
    nested: {
      paddingLeft: theme.spacing(4),
      
    },
  }));
const Monitoreo = () => {
    const source = axios.CancelToken.source();    
    let history = useHistory();   
    const classes = useStyles();
    const[listObjeto,setListObjeto]=React.useState([])
    const[listCoodTipo,setListCoodTipo]=React.useState([])
    const[listSectorMap,setListSectorMap]=React.useState([])
    const[listObjetos,setListObjetos]=React.useState([])
    const[listCuadrante,setListCuadrante]=React.useState([])
    const[listGpsUnidad,setListGpsUnidad]=React.useState([])
    const[listGpsUnidad2,setListGpsUnidad2]=React.useState([])
    const [listNvoCam, setListNvoCam] = React.useState([]);
    const [listTorres, setListTorres] = React.useState([]);
    const [listRepetidor, setListRepetidor] = React.useState([]);
    const [listCamara, setListCamara] = React.useState([]);
    const [PoligEntrada, setPoligEntrada] = React.useState([]);
    const libreriaG=[process.env.REACT_APP_GOOGLE_LIBRARIE]
    React.useEffect(()=>{
        let token =localStorage.getItem('token20') ;    
        async function llamaListCoordTipo(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                { let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCoodTipo(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListObjeto(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-tipo-list`;   
            let dataInfo = qs.stringify({                   
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListObjeto(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListSectorMap(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/seguridad-sector-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,                  
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    //let nvoFilt=[]
                   /*  aux.forEach(sector => {
                        nvoFilt.push({Lat,Lon})
                    }); */
                    setListSectorMap(filtrado)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListCuadrante(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-coordenada-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,   
                    'tipo':0,               
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)
                    setListCuadrante(filtrado)
                    /* function prbC(){
                        console.log("dentro del toast")
                    }                                        
                    let mensaje="cargando datos"
                    toast.success(mensaje,{
                        pauseOnFocusLoss: false,                    
                        toastId: `prb-map01`,
                        onClick:()=>prbC()
                    }) */
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        async function llamaListObjetos(){          
            let auxU=process.env.REACT_APP_LINKTEZO +`/mapa-objeto-list`;   
            let dataInfo = qs.stringify({ 
                    'llave':0,        
                    'tipo':0,          
                    'idDispositivo':'w845sdc15ds3dc1s'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },              
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1)
                {   let filtrado= aux.filter((tipo)=>tipo.Id!==0)             
                    generaCoordObj(filtrado)
                    setListObjetos(filtrado)                    
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
                else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        history.push("/");                              
                    } 
                }
            })
        }
        llamaListCuadrante()
        llamaListObjeto()
        llamaListCoordTipo()
        llamaListSectorMap()
        llamaListObjetos()
    },[])    

    React.useEffect(()=>{    
        const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta 

        socket.on("GPSUnidadActual", (GPSSocket) => {                  
            //console.log(JSON.parse(GPSSocket));            
            let auxGps=JSON.parse(GPSSocket)            
            setListGpsUnidad2([auxGps,...listGpsUnidad2])
             if (auxGps.Lat!==0 && auxGps.Lon !==0) 
            {   let nvoRegistro=[]
               // console.log(auxGps);
                let aux1 =generaCoordenadaDinam(auxGps.Lat ,auxGps.Lon)
                if (listGpsUnidad.length!==0) // verifica que tenga unidades registradas y filtra las que tienen actualizaciones
                {   let filtrado=listGpsUnidad.filter((gpUnidad)=> gpUnidad.usuPatrulla!==auxGps.UnidId)                    
                    filtrado.push({coordenada:aux1, usuPatrulla:auxGps.UnidId,fecha:auxGps.fecha })   
                    nvoRegistro=filtrado                    
                    setListGpsUnidad(nvoRegistro)
                }                
                else
                {    nvoRegistro.push({coordenada:aux1, usuPatrulla:auxGps.UnidId ,fecha:auxGps.fecha})                    
                    setListGpsUnidad(nvoRegistro)
                }
                
            }
        });   

        return () => socket.disconnect();     
    })    

    const generaCoordObj=(array)=>{        
        let auxListCamara=[]
        let auxListGen=[]
        let auxListVia=[]
        let auxListRepetidor=[]
        let auxListTorres=[]
        let auxListNvoCam=[]
        array.forEach((elemento)=>{
            if (elemento.Lan !== 0 && elemento.Lon!==0 && elemento.TipoId!==4) 
            {        
              let auxPrev=generaCoordenadaDinam(elemento.Lan,elemento.Lon)
              let valida=true
              if (valida && elemento.TipoId===1) 
              { valida=false
                auxListCamara.push({coordenada:auxPrev,original:elemento})
                
              }
              if (valida && elemento.TipoId===3) 
              { valida=false
                auxListRepetidor.push({coordenada:auxPrev,original:elemento})
              }
              if (valida && elemento.TipoId===5) 
              { valida=false
                auxListTorres.push({coordenada:auxPrev,original:elemento})
              }
              if (valida && elemento.TipoId===2) 
              { valida=false
                auxListNvoCam.push({coordenada:auxPrev,original:elemento})
              }
              else
              { auxListGen.push({coordenada:auxPrev,original:elemento})
              }        
            }
            else
            {   if (elemento.TipoId===4 && elemento.detalle) 
                {   let auxPolyne=[]
                    elemento.detalle.forEach((auxVia)=>{
                        if (auxVia.DetLat!==0 && auxVia.DetLon!==0) 
                        {   let auxP1= generaCoordenadaDinam(auxVia.DetLat,auxVia.DetLon)
                            auxPolyne.push(auxP1)
                        }            
                    })
                    auxListVia.push({coordenada:auxPolyne,original:elemento})
                }  
            }
      
          })
          console.log(auxListCamara);    
        setListNvoCam(auxListNvoCam)
        //setListNvoCam([])
        setListTorres(auxListTorres)
        setListRepetidor(auxListRepetidor)
        setListCamara(auxListCamara)        
        setPoligEntrada(auxListVia) 
        //setListGen(auxListGen)                         
    }

    const generaCoordenadaDinam=(auxLat,auxLng)=>{
        let coordenada=[]
        let aux1=auxLat;            
        aux1=parseFloat(aux1)
        let aux2=auxLng;            
        aux2=parseFloat(aux2)        
        coordenada={ lat : aux1, lng :aux2 }
      return coordenada  
    }
 


    let altuTamMax =800
    const   tamanioH= (window.innerHeight<altuTamMax ? window.innerHeight-100: window.innerHeight - 100)
    return (
    <div>
        <Box width={100+'%'} >
            {  listSectorMap.length!==0  && listObjetos.length!==0 && listCuadrante.length!==0?
                <MapaAlerta  Seleccionado={listSectorMap} 
                    tamanio={{height: tamanioH, width: "100%"}}
                    listObjetos={ listObjetos  } listCuadrante={listCuadrante}
                    listGpsUnidad={listGpsUnidad} PoligEntrada={PoligEntrada}
                    listCamara={listCamara} listRepetidor={listRepetidor} listTorres={listTorres}
                    listNvoCam={listNvoCam} libreriaG={libreriaG}
                />
            :<Box mt={5}><LinearProgress/></Box>   }
        </Box>
    </div>
    )
}

export default Monitoreo