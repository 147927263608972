import React , {useState,useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Card, Box, Typography} from '@material-ui/core'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {ErrorGeneral, llamaApiFormData} from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display:'flex',
    flexDirection:'row'
  },

  formSelect: {
    width:'12em'
  },

  formDesc:{
      marginTop:'1.7em',
      width:'30em',
      height:'4em',
      display:'flex',
  },
  formImg:{
    marginTop:'.5em',
    width: 25+'rem',
    height:25+'rem',
    borderRadius: '.6rem'
  },
  cardDatos:{
    width:'100%',
    height:30.5+'rem',
},
}));

export default function EditarImagen({modalAbierto,setModalAbierto,seleccionado,titulo,setActualizar,actualizar}){
  const classes = useStyles();
  let history = useHistory();
  const source = axios.CancelToken.source(); 
  const [espera,setEspera]=useState(false)
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
  const [botonesDeshab,setBotonesDeshab] = useState(false)
  const [aviso, setAviso] = useState(false);
  const [imgPrimaria, setImgPrimaria] = useState();
  //const [imgSecundaria,setImgSecundaria] = useState()
  const [portadaEnviar, setPortadaEnviar] = useState();
  const [progresoEnvio, setProgresoEnvio] = useState();
  const [contraEnviar,setContraEnviar] = useState()
  const [reiniciar,setReiniciar] = useState(false)
  const auxDispV = localStorage.getItem("Dispvo") && localStorage.getItem("Dispvo").length >= 12 
  ? localStorage.getItem("Dispvo") : "451224452121";
  useEffect(()=>{
      console.log(seleccionado);
      if(seleccionado.SegFoto)
      {   setImgPrimaria(seleccionado.SegFoto)
      }
      else
      {   setImgPrimaria("/LOGO POLICIA ATIZAPAN 2.svg" )
      }      

  },[seleccionado])

  const onChangeImgPrimaria = (e) =>{
      const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/
      if(e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex))
      {   setImgPrimaria(URL.createObjectURL(e.target.files[0]))
          setPortadaEnviar(e.target.files[0])
      }
      else
      {   toast.warning("Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png",{
              pauseOnFocusLoss: false,toastId: `incorrecto-selPrim`
          }); 
          setImgPrimaria("T_LOGO_1.svg" )
          setPortadaEnviar()
      }
  }   

  const subirImagen = (auximg,auxtipo,auxtitulo) => {
    setBtnDeshabilitado(true)
    setBotonesDeshab(true)
    const usuId =localStorage.getItem('UsuId') ;    
    const formData = new FormData();
    formData.append('usuario',usuId)
    formData.append('elemento',seleccionado.SegId)
    formData.append('idDispositivo',auxDispV)
    formData.append('profile',auximg)
    const url = 'usrseg-set-foto';

    function respuesta(aux) {
      if(aux.success)
      {   const mensaje = auxtitulo+" subida exitosamente";
        setTipoAdvertencia("success");
        setMensaje(mensaje);
        setAviso(true);   
        setPortadaEnviar()                                
        toast.success(mensaje,{
            pauseOnFocusLoss: false,                    
            toastId: `exito-env${auxtipo}`
        })
        setReiniciar(true)
      }
    }

    llamaApiFormData(formData, url, respuesta, ErrorGeneral, setEspera, history, source, auximg.size, setProgresoEnvio);
  }

  function verificarImg (img){
    let validado=false;
    if(img && img.size)
    {   validado = true;
    }
    else
    {   validado = false;
    }
    return validado;
  }

   function handleSubmission (){
    if(verificarImg(portadaEnviar))
    { if(portadaEnviar)
      { subirImagen(portadaEnviar,1,'Fotografia');
      }        
    }
    else
    { setMensaje("Seleccione una foto");
      setAviso(true);
      setTipoAdvertencia("error");
    }
  }

  return(
  <Modal
    titulo={titulo} modalAbierto={modalAbierto} mensaje={mensaje}
    setModalAbierto={setModalAbierto} guardarDatos={handleSubmission}
    tipoAdvertencia={tipoAdvertencia} aviso={aviso} 
    btnDeshabilitado={btnDeshabilitado} reiniciar={reiniciar}      
    setAviso={setAviso} tamanio={'l'} cargando={espera}   
    actualizar={actualizar} setActualizar={setActualizar}        
  >
  <form className={classes.root} noValidate autoComplete="off"> 
    <Card className={classes.cardDatos} >
      {/* <Typography id="primaria" style={{textAlign:'center'}}>
        <b>Foto</b>
      </Typography> */}
      <Box display="flex" flexDirection="column">
        <img className={classes.formImg} hidden={!imgPrimaria}  
          src={imgPrimaria ? imgPrimaria : ""} alt="Imagen Primaria"
        />
        <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
          <input type="file" onChange={(e)=>onChangeImgPrimaria(e)}  id={"imgPrimaria"}
            accept="image/jpg,image/jpeg,image/png"disabled={botonesDeshab}  hidden
          />
          <label htmlFor={"imgPrimaria"} >
            <Button variant="contained" component="span" color="primary" 
              size='small' disabled={botonesDeshab}  
            >
              Cargar IMG
            </Button>
          </label>
        </Box>
      </Box>
    </Card>      
  </form>
  </Modal>
  )
}