import React from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,
    Tooltip,IconButton,Card} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
  const useStyles = makeStyles({    
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: { "$selected &": { color: "white" } },
    hover: {},
    selected: {}
  });
export const vacia=(key) => <TableCell  align="center" key={key}> </TableCell> 

export const Celda=({align,estilo,children,key,claseExt})=>{
  const classes = useStyles();
    return (
    <TableCell align={align?align:"center"} key={key} className={claseExt? claseExt:classes.tableCell} 
      padding={'none'} style={estilo} 
    >
      {children}
    </TableCell>
    )
}


export const celdaFuncion=(align,estilo,children,key,claseExt)=>{
  
    return (
    <TableCell align={align} key={key} className={claseExt} 
      padding={'none'} style={estilo} 
    >
      {children}
    </TableCell>
    )
}

export const celdaIconoFuncion=(leyenda,funcionModal,childrenIco,registro)=>{//incompleta
    let auxCont=<Tooltip title={leyenda}>
    <IconButton   aria-label="add circle" size="small" onClick={() => funcionModal(registro)} component="span">
      {childrenIco }
    </IconButton>                     
  </Tooltip>
    return(
      celdaFuncion("center",{width:10+'px'}, auxCont)
    )
}

export const celdaIcono=(leyenda,childrenIco,key,clase)=>{
    let auxCont=<Tooltip title={leyenda}>{childrenIco }</Tooltip>
    return(
      celdaFuncion("center",{width:10+'px'}, auxCont,key,clase)
    )
}

export const TablaGeneral = (cuerpo,cabeza,estiloCont) => {   
    return (
    <TableContainer className={estiloCont}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                {cabeza}
            </TableHead>
            <TableBody>
                {cuerpo}
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export function celdaCard(auxColorStd,etiqueta ,estilo,alinear)
{  
  let renglon=(
    <TableCell  align={alinear} padding={'none'} style={estilo} >
      <Card style={{color:auxColorStd==="#EBF1F8"? "black":'white', fontWeight:"bold", backgroundColor:auxColorStd}}>
        {etiqueta}
      </Card>
    </TableCell>    )
  return renglon
} 