import React,{useState,useEffect} from 'react';
import {Button,CssBaseline,TextField,Typography
        ,Container,CircularProgress  ,Box,CardMedia} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { useHistory } from "react-router-dom";
import axios from 'axios';
//import jwt from 'jsonwebtoken';
import Alert from '@material-ui/lab/Alert';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
const Login = () => {
    let history = useHistory();
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const {guardarUsuario}=React.useContext(AuthContext);
    const {guardarGenerales}=React.useContext(GeneralesContext);  
    const [espera,setEspera]= useState(false);
    const [usuario,setUsuario]= useState(  { nombre:'',contrasenia:'' } )
    const {nombre,contrasenia}=usuario    
    const classes = useStyles();
    useEffect(()=>{ 
        const veriSesion = async () => {
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');
            const auxTerminal= localStorage.getItem('UsuTerminal');
            if( usu !== null )
            {
                guardarUsuario( { Nombre : usu, nivel : nvl } );   
                guardarGenerales( { Titulo : 'Listado Clientes', Terminal : auxTerminal } )     
                //history.push("/lista-usuario")		
                history.push("/bitacora")
            }
            else
            {
                guardarUsuario([]);   
                guardarGenerales([])   
                //document.title = 'Blanco-Inmob Login'  
            }
        }
        veriSesion();				
    },[]);

    function llamada() {
        async function autenticar(){           
            setEspera(true);
            const auxU=process.env.REACT_APP_LINK +`autenticar-web-adm`;            
            let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'content-type' : 'application/json'},
            data: {
                user:nombre,                               
                pass:contrasenia,
                idDispositivo:"987ygubhjnjik"                                      
            }
            };
            let aux=[];
            await axios(config)
            .then(res => {        
                aux=res.data
                //console.log("dentro de la llamada");
                if (aux) 
                {   let auxCode=uuidv4()       
                    const auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
                    localStorage.setItem('UsuarioNom',aux.userNombre);
                    localStorage.setItem('UsuId',aux.userId);              
                    localStorage.setItem('Tipo',aux.userTipo);
                    localStorage.setItem('token20',aux.userToken); 
                    localStorage.setItem('Dispvo',auxRecorte);   
                   //localStorage.setItem('UsuTerminal',aux.userTerminal);            
                    guardarGenerales({Titulo:'Listado Clientes',Terminal:aux.userTerminal})   
                    guardarUsuario({Nombre:nombre,nivel:aux.userTipo});                      
                    history.push("/bitacora")	  
                    //history.push("/lista-usuario")		  
                }
                else
                {
                    setAviso(true);
                    setMensaje("Usuario o contraseña incorrecta")
                }                           
            }).catch(auxerror => {                
                if(auxerror && auxerror.response && auxerror.response.status === 500)
                {
                    console.log(auxerror.response.data.mensaje);          
                }
            });
            setEspera(false);
        }  
        autenticar();    
    }
    
    const handleCloseSN = () => {
        setMensaje('');
        setAviso(false);
    };
    
    const onSubmit = e =>
    {   e.preventDefault();    
        if (nombre.length>=4&&contrasenia.length>=4)
        {   llamada();     
            const usu= localStorage.getItem('UsuarioNom');
           
             if(usu)
            {   console.log("volviendo a cambiar");
                history.push("/lista-usuario")		  
            }     
        }
        else
        {
            setAviso(true);
            setMensaje("Debe llenar  Usuario o Contraseña correctamente")
        }                                        
	}
    const onChange = e =>
	{  
        let expNopermitida = new RegExp('[%{}*|`]');        
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.:;!?,<>=]');
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
            !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        {
            setUsuario({
                ...usuario, 
                [e.target.name] : e.target.value  
            })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};
   
    return(//
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box>
                    <CardMedia style={{marginBottom:2+"rem",width:"15.5rem",height:"15.5rem"}}
                        component="img" alt="Logo atizapan" 
                        image="/LOGO POLICIA ATIZAPAN 2.svg" title="Logo atizapan"
                    />
                </Box>
              {/*   <Box>
                    <CardMedia style={{marginBottom:1+"rem",width:"8rem"}}
                        component="img" alt="Logo tip" height="90" 
                        image="/LOGO_DEGRADADO_TIP.svg" title="Logo tip"
                    />
                </Box> */}
                <Typography component="h1" variant="h5">
                    Iniciar Sesión
                </Typography>
                { aviso && mensaje.length !== 0 ? 
                    <Alert severity="warning" onClose={handleCloseSN}> { mensaje } </Alert>
                    :null
                }
                <form className={classes.form} onSubmit={onSubmit} noValidate >
                    <TextField
                        variant="outlined" margin="normal" required fullWidth id="nombre"
                        label="Usuario" name="nombre" type="text" value={nombre}              
                        onChange={onChange} autoFocus
                    />
                    <TextField
                        variant="outlined" margin="normal" required fullWidth
                        name="contrasenia" label="Contraseña" type="password" id="contrasenia"
                        value={contrasenia} onChange={onChange}           
                    />           
                    <Box align="center">
                        {!espera ?
                            <Button type="submit" fullWidth
                                variant="contained" color="primary"  className={classes.submit}
                            >
                                Iniciar Sesión
                            </Button> 
                            : <Box mt={2}> <CircularProgress /> </Box>
                        }
                    </Box>                       
                </form>
            </div>        
        </Container>
    );
}

export default Login
