import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box } from "@material-ui/core";
export default function IntervaloSelect({
  fechasSeleccionadas,
  setFechasSeleccionadas,
}) {
  //console.log(new Date("2022-05-01T08:00"));
  const { inicio, final } = fechasSeleccionadas;
  const guardarInicio = (auxfecha) => {
    setFechasSeleccionadas({
      ...fechasSeleccionadas,
      inicio: auxfecha,
    });
  };
  const guardarFinal = (auxfecha) => {
    setFechasSeleccionadas({
      ...fechasSeleccionadas,
      final: auxfecha,
    });
  };

  return (
    <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
      <Box component="span"   display="flex" flexDirection="column" >
        <Box ml={1} component="span" mb={1} style={{width:10+'rem' }}>
          <DateTimePicker disableFuture autoOk hideTabs
            id="unid-FInicial" format="dd/MM/yyyy HH:mm" ampm={false}            
            views={["year", "month", "date","hours" ,"minutes"]}
            label="Fecha Inicial" size="small"
            minDate="2017-03-14" maxDate={new Date()}
            value={inicio} onChange={guardarInicio}
            style={{ width: 9 + "rem" }}
          />
        </Box>
        
        <Box ml={1} component="span" style={{width:14+'rem'}}>
          <DateTimePicker  disableFuture autoOk hideTabs
            id="unid-Ffinal" format="dd/MM/yyyy HH:mm" ampm={false} 
            label="Fecha Final" size="small"
            views={["year", "month", "date" ,"hours" ,"minutes"]}
            minDate={inicio} maxDate={new Date()}
            value={final} name="final"            
            style={{ width:9 + "rem" }}
            onChange={guardarFinal}
          />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
