import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box,TextField,Tooltip,FormControl,//Select as MaterialSelect ,
        FormLabel,FormControlLabel,RadioGroup,Radio,
        InputAdornment,IconButton,Input,
        InputLabel,Select,MenuItem} from '@material-ui/core' 
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";
import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrar';
import moment from 'moment'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {ErrorGeneral, llamaApiCargaToken, } from "../funciones/ClienteAxios";
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const EditarElemento = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,usrSelecc}) => {
    let history = useHistory();
    //const classes = useStyles();
    const colorCheckR='#0EA7E0'
    const source = axios.CancelToken.source();     
    let fechaMax = moment(new Date()).subtract(18,'years')  
    const[nvoCliente,setNvoCliente]= useState({Nombre:'',Paterno:'',Materno:'',Curp:'',FNacimien:fechaMax,Tipo:'',NumIdentifi:'',
                                                    Email:'',Genero:'',Pass:'',Tel:'',usuario:'',Puesto:'',Placa:'',Folio:'',
                                                    usrID:""
                                                    });                                                    
    const [listTipo, setListTipo] = useState([]); 
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);    
    const[auxPass,setAuxPass]= useState('123456');          
    const[visiblePwd,setVisiblePwd]=useState(false)                                          
    const{  Nombre,Paterno,Materno,Curp,FNacimien,NumIdentifi, Email, Genero,Pass
            ,Tel,usuario ,Puesto ,Placa ,Tipo,Folio,usrID}=nvoCliente
        
    useEffect(()=>{
        //console.log(usrSelecc);
        let auxFecha= moment.utc( usrSelecc.SegNac).format('YYYY-MM-DD HH:mm')
        let auxFecha2 =auxFecha === "1900-01-01 00:00"? moment(auxFecha).add(1,'hour'):auxFecha

        setNvoCliente({...nvoCliente,
            Nombre: usrSelecc.SegNom ,Paterno: usrSelecc.SegPat ,Materno: usrSelecc.SegMat,
            Curp: usrSelecc.SegCurp ,FNacimien:auxFecha2,
            NumIdentifi: usrSelecc.SegIne ,Email: usrSelecc.UsrEmail ,Genero: usrSelecc.SegGenero ,Pass: usrSelecc.UsrPass,
            Tel: usrSelecc.SegTel ,usuario:usrSelecc.UsrUsuario ,Puesto:usrSelecc.UsrPuesto ,Placa:usrSelecc.SegPlaca ,
            Tipo:usrSelecc.TipoId ,Folio:usrSelecc.SegFolio,usrID:usrSelecc.UsrId
        }) 
        setAuxPass(usrSelecc.UsrPass)             
         
        llamadaTipo()

        return ()=> {
            source.cancel();
        }   
    },[])

    const llamadaTipo = () => {
        const usuId =localStorage.getItem('UsuId') ;                     
        const data = qs.stringify({
            usuario:usuId  ,            
            idDispositivo:'w845sdc15ds3dc1s'
        });
        const url = "usrseg-tipo-list";
  
        function respuesta(aux) {
            if (aux.length !== 0) 
            {    
                setListTipo(aux)                    
            } 
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
    };  

    const verificaINE = (campo,descripcion)=>{
        let respuesta =false;                  
        if( ( (campo.length===0 || campo.trim() === null || campo.length<18) )
        ) 
        {   respuesta=true;        
            //console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const RelojFNacimiento = ()=>
    { return <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>            
        <Tooltip title="Seleccionar Fecha Nacimiento">
          <Box  ml={1}  component="span" width={1/4}>           
            <DatePicker style={{width:8.5+'rem'}}
              disableFuture  format="dd/MM/yyyy"
              views={["year", "month", "date"]}
              label="Fecha Nacimiento" size="small"
              openTo="year" cancelLabel={"Cancelar"}                  
              okLabel="Seleccionar"   invalidLabel={"Error en la fecha"}
              minDate="1900-01-01" maxDate={fechaMax}
              value={FNacimien} onChange={guardarFNaci}            
            />                          
          </Box>    
        </Tooltip>                                             
        </MuiPickersUtilsProvider>
    }
             
    const guardarFNaci = auxfecha =>
    {   setNvoCliente({ ...nvoCliente,
            FNacimien : auxfecha  
        })
    };
    
    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$.@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		
	};   

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');          
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');            
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		    
	}; 
 
    const guardaCheckRad = e =>
    {  //console.log(e);
        setNvoCliente({
            ...nvoCliente,
            [e.target.name] : e.target.value  
        })  
    };     
   
    const llamadaNvoCliente = (auxFechaNacimient,edad) => {                        
        const data = qs.stringify({
            llave:usrID,// el id delsuario
            tipo:Tipo,                         
            status:1,
            nombre:Nombre,
            paterno:Paterno,
            materno:Materno,
            usuario:usuario,  
            pass:auxPass,
            email:Email,  
            puesto:Puesto,
            placa:Placa,
            folio:Folio,          
            curp:Curp,
            ine:NumIdentifi,
            genero:Genero,
            nacimiento:auxFechaNacimient,//1997-10-11T00:00:00,                        
            tel:Tel,                                   
            idDispositivo:'w845sdc15ds3dc1s'
        });
        const url = "usrseg-editar";
  
        function respuesta(aux) {
            if (aux.length!==0) 
            {                
              //setNvoRegistro(aux[0]);  
              setActualizar( !actualizar)              
              setBtnDeshabilitado(true)                                             
              let mensaje="Se edito correctamente el  Usuario"
              setTipoAdvertencia("success")      
              setMensaje(mensaje);
              setAviso(true)     
            }      
        }
        llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
    };  

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                    ...nvoCliente, //copiamos el nvocliente
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};  

    const onChangeContra = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setAuxPass(e.target.value)
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};  

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
       //console.log(campo);   
        if( (typeof campo !== 'number' && typeof campo === 'string' 
             && (campo.trim() === '' || campo.trim() === null) )
            )        
        {   respuesta=true;                    
            mandaAlerta('No se lleno el campo  '+descripcion ,"warning")                        
        }
        return(respuesta);
    }

    const verificaNulosSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if(campo === '' || campo === null ||campo == 0 )
        {   respuesta=true;                             
            mandaAlerta('Debe seleccionar campo '+descripcion ,"warning")            
        }
        console.log(campo+descripcion);  
        return(respuesta);
    }
    
    function verificaPoliDatos() {
        let respuesta=false
        if ( !verificaNulos(Tipo,'Tipo') /* && !verificaNulos(Puesto,'Puesto') 
            && !verificaNulos(Placa,'Placa') && !verificaNulos(Folio,'Folio')  */) 
        {
            respuesta=true
        }
        return respuesta
    }

    const verificaCurp=(Curp)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (Curp.trim().length===18) 
        {
            let auxnom=Curp.substring(0,4);
            let auxfech=Curp.substring(4,10);
            let auxdesc=Curp.substring(10,16);
            let auxgen=Curp.substring(10,11);
            let auxnumer2=Curp.substring(16,18);
            if ( ( expCadena.test(auxnom) && !expNumero.test(auxnom) ) && ( expNumero.test(auxfech) && !expCadena.test(auxfech) ) 
             &&( !expNumero.test(auxdesc) && expCadena.test(auxdesc)) && ( expCadena.test(auxgen)&& !expNumero.test(auxgen) )
             &&(expNumero.test(auxnumer2) && !expCadena.test(auxnumer2) ) ) 
            {
                if (auxgen==='H'||auxgen==='M') 
                {    respuesta =true ;                    
                }   
                else
                {   mandaAlerta('Debe escribir un CURP valido ',"warning")
                }
            }
            else
            {   mandaAlerta('Debe escribir un CURP valido ',"warning")            
            }
        } 
        else 
        {    mandaAlerta('Campo CURP incompleto, debe escribir un CURP valido ',"warning")            
        }
        
        return(respuesta);
    }    
    
    const verificaNombre=(nombre,appat,apmat)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (!verificaNulos(nombre,'Nombre') && !verificaNulos(appat,'Apellido Paterno') && !verificaNulos(apmat,'Apellido Materno')  ) 
        {                 
            if( expCadena.test(nombre) && !expNumero.test(nombre) && nombre.length>=3 )
            {
                if(  expCadena.test(appat) && !expNumero.test(appat) && appat.length>=3)
                {
                    if ( expCadena.test(apmat) && !expNumero.test(apmat) && apmat.length>=3 ) 
                    {  
                        respuesta=true;                     
                    }else
                    {   mandaAlerta('Debe escribir un Apellido Materno  valido',"warning")                        
                    }
                }
                else
                {   mandaAlerta('Debe escribir un Apellido Paterno  valido ',"warning")                    
                }
            }
            else
            {   mandaAlerta('Debe escribir un Nombre  valido ',"warning")                
            }    
        }    
        return(respuesta);
    }
    
    const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true; 
            mandaAlerta('Campo Email vacio, debe llenar el campo ',"warning")                 
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   mandaAlerta('Debe escribir un Correo valido  ',"warning")                     
            }            
        }
        return(respuesta);
    }       

    const veriNuloNum = (campo,descripcion)=>{
        let respuesta =false; 
        console.log(descripcion+ ' '+campo); 
        console.log(campo);                                 
        if((campo==='0'||campo===0 )|| campo === null ||campo === ""|| campo === " ")
        {   respuesta=true;                    
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }    

    function valContra() {
        let respuesta=false
        if ( !verificaNulos(auxPass,'Contraseña') ) 
        {            
            if (auxPass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    } 

    function validaVariable(auxCampo,auxMensaje,auxTamanio) {
        let respuesta=false        
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if ((typeof(auxCampo) === 'number'&& auxCampo.toString().length>=auxTamanio )
            ||( typeof(auxCampo) === 'string'&& auxCampo.length>=auxTamanio )
            ) 
            {   respuesta=true                    
            }
            else
            {   mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }

    function verificaUsu() {
        let respuesta=false
        if ( !verificaNulos(usuario,'Usuario') ) 
        {   if (usuario.length>=4 ) 
            {   respuesta=true                    
            }
            else
            {   mandaAlerta('Campo Usuario incompleto, minimo 4 caracteres , debe escribir un Usuario valido',"warning") 
            }
        }        
        return respuesta;
    }

    function verificaCorreo2() {
        let respuesta=false
        if ( !verificaNulos(Email,'Email') ) 
        {   if (Email.length>=4 ) 
            {   respuesta=true                    
            }
            else
            {   mandaAlerta('Campo Email incompleto, minimo 4 caracteres , debe escribir un Email valido',"warning") 
            }
        }        
        return respuesta;
    }

   /*  function guardarDatos(e)
    {   e.preventDefault()                
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')            
        if (  verificaNombre(Nombre,Paterno,Materno) && valFechNacimiento()  && (!verificaINE(Curp,'CURP') )
            && !verificaINE(NumIdentifi,'INE') && verificaUsu()  && valContra() ) //se cambio el verificar email por usuario
        {   if ( !verificaNulosSelect(Genero,'Genero') && validaVariable(Tel,'Telefono',10)) 
            {   setMensaje('');
                setAviso(false);                 
                llamadaNvoCliente( auxFechaNacimient+'T00:00:00')                                             
            }          
        }                                  
    } */

    /* function guardarDatos(e)
    {   e.preventDefault()        
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
        if ( verificaNombre(Nombre,Paterno,Materno) && !verificaINE(Curp,'CURP') 
            && !verificaINE(NumIdentifi,'INE')  && !verificaNulosSelect(Genero,'Genero') && verificaPoliDatos() &&
            verificaUsu() && valContra() && verificaCorreo2() && validaVariable(Tel,'Telefono',10) )
        {            
            setMensaje('');                
            setAviso(false);                 
            llamadaNvoCliente( auxFechaNacimient+'T00:00:00')        
        }                                  
    } 
     */
    function guardarDatos(e)
    {   e.preventDefault()        
        let auxFechaNacimient=moment( FNacimien).format('YYYY-MM-DD')  
        if ( verificaNombre(Nombre,Paterno,Materno) //&& !verificaINE(Curp,'CURP') && !verificaINE(NumIdentifi,'INE') && verificaCorreo2() 
            && !verificaNulosSelect(Genero,'Genero') && verificaPoliDatos() &&
            verificaUsu() && valContra() && validaVariable(Tel,'Telefono',10) )
        {            
            setMensaje('');                
            setAviso(false);                 
            llamadaNvoCliente( auxFechaNacimient+'T00:00:00')        
        }                                  
    } 

    function onChangeColonia(e){
        //setElemento(e.target.value)
        setNvoCliente({
            ...nvoCliente, //copiamos el nvocliente
            [e.target.name] : e.target.value  
        })
    }
 
    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
      };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();
        //setVisiblePwd(!visiblePwd)
    };

    const DatosGenerales=(
        <div style={{marginTop:.8+'rem'}}>                                
             <TextField required id="Nombre-input" onChange={onChange} style={{marginRight:.8+'rem',width:11+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" // defaultValue="Nombre"                
            />            
            <TextField required id="Paterno-input" onChange={onChange} style={{marginRight:.8+'rem',width:10+'rem'}}
                label="Apellido Paterno"  value={Paterno} name='Paterno'size="small" //defaultValue="Paterno"
            />            
            <TextField  id="Materno-input" onChange={onChange} style={{marginRight:.8+'rem',width:10+'rem',paddingBottom:1+'rem'}}
                label="Apellido Materno"  value={Materno} name='Materno' size="small"//defaultValue="Materno"
            />
            <RelojFNacimiento/>
            
            <br/>
            
            <TextField required id="Curp-input" onChange={onChange} style={{paddingRight:1.5+'rem'}}
                label="CURP" defaultValue="Curp" value={Curp} name='Curp' size="small"
                inputProps= {{ maxlength: 18 }}
            />       
            <TextField required id="INE-input" onChange={onChange} style={{marginRight:1.5+'rem'}}
                label="INE" defaultValue="INE" value={NumIdentifi} name='NumIdentifi' size="small"
               inputProps= {{ maxlength: 18 }}
            />               
            <FormControl component="fieldset" style={{paddingBottom:1+'rem' }} size="small" >              
                <FormLabel component="legend" style={{color:colorCheckR,fontWeight:500}}>
                    Genero
                </FormLabel>      
                <RadioGroup   row   aria-label="position" defaultValue="top"   
                    name="Genero" value={Genero} onChange={guardaCheckRad}
                >                                
                <span> 
                    <FormControlLabel value={'H'} style={{color:colorCheckR}} 
                        control={<Radio color="primary" />} label={'Hombre'} />                                
                    <FormControlLabel value={'M'} style={{color:colorCheckR}} 
                        control={<Radio color="primary" />} label={'Mujer'} />
                </span>                        
                </RadioGroup>                     
            </FormControl>    
            <br/>

            {listTipo.length!==0 ?                
                <FormControl  style={{marginRight:1+'rem', minWidth:7.5+'rem',paddingBottom:1+'rem'}} >
                    <InputLabel id="tipo"> Tipo </InputLabel>
                    <Select  
                        label="Tipo" id="Tipo" name="Tipo"
                        value={Tipo} onChange={onChangeColonia}
                    >
                    {listTipo.map((auxTipo, index) => (
                        <MenuItem value={auxTipo.TipoId} key={auxTipo.TipoId}>
                            <em>{auxTipo.TipoNom }</em>
                        </MenuItem>
                    ))}
                   </Select>
                </FormControl>
            :   null
            }     
            <TextField required id="Puesto-input" onChange={onChange} style={{marginRight:1+'rem',width:10+'rem'}}
                label="Puesto"  value={Puesto} name='Puesto' size="small"               
            />    
            <TextField required id="Placa-input" onChange={onChange} style={{marginRight:1+'rem',width:10+'rem'}}
                label="Placa"  value={Placa} name='Placa' size="small"               
            />    
            <TextField required id="Folio-input" onChange={onChange} style={{marginRight:1+'rem',width:10+'rem'}}
                label="Folio"  value={Folio} name='Folio' size="small"               
            />    
            <br/>

            <TextField  id="usuario-input" onChange={onChangeCorreo} style={{marginRight:1+'rem',paddingBottom:1+'rem',width:9+'rem'}}
                label="Usuario"  value={usuario} name='usuario' size="small" autoComplete='off'
            />                 
            <FormControl  size="small" style={{width:10.5+'rem',marginRight:1+'rem'}} >
                <InputLabel htmlFor="standard-adornment-password" size="small">
                    Contraseña
                </InputLabel>
                <Input id="input-adornment-password" size="small"
                    type={visiblePwd ? 'text' : 'password'} value={auxPass}  
                    autoComplete='off' name="auxPass" onChange={onChangeContra } 
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>  
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{marginRight:1+'rem' ,paddingBottom:1+'rem',width:13+'rem'}}
                label="Email"  value={Email} name='Email' size="small"  autoComplete='off'
            />       
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{ width:7 +'rem'}}
                label="Telefono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxlength: 10 }} autoComplete='off'
            />                                               
        </div>            
    )
       
    return (
    <>           
    <Modal
        titulo={titulo} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'md'} cargando={espera}        
    >
        {DatosGenerales}
    </Modal>         
    </>
    )
}

export default EditarElemento