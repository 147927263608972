import jwt from "jsonwebtoken";
export function  authUser(){
        const token = localStorage.getItem("token20");
        let validar = false;
        if (token) {
          jwt.verify(
            token,
            process.env.REACT_APP_SERV_TOKEN_KEY,
            { algorithm: ["HS512"] },
            (err, decoded) => {
              if (decoded) {
                validar = true;
              } else {
                validar = false;
              }
            }
          );
        }else{
          validar = false;
        }
        return validar;
}