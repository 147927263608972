import React, {useState, useEffect} from 'react';
import {FormControl,Grid,Button,InputLabel, MenuItem, TextField,Select,TextareaAutosize} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/Modal';
import { toast } from 'react-toastify';
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    root: {
      margin: {
        margin: theme.spacing(1),
      },
    },
    formEncabezado:{
      marginTop:'1.7em',  
    },
    formAnotacion: {
      width:'20em'
    },
    formSelect: {
      width:'8em'
    },
    formSelectElemento:{
        width:'20rem'
    },
    formObs:{
        marginTop:'1.7em',
        width:'30em',
        height:'6em'
    },
  }));
const ENDPOINT = "https://sockets.daduva.mx:5000";

export default function NuevaAlertaBitacora({registro,modalAbierto,setModalAbierto,titulo,subtitulo,setFuncion}){
    const classes = useStyles();
    const source = axios.CancelToken.source();    
    let history = useHistory();
    const usuID= localStorage.getItem('UsuId');    
    const [listaStatus, setListaStatus] = useState([]);
    const [listaElementos, setListaElementos] = useState([])
    const [elemento, setElemento] = useState(registro.SegElemId)
    const [espera, setEspera] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [status, setStatus] = useState(registro.StatusId);    
    const [direccion,setDireccion] = useState("");
    const [observacion,setObservacion] = useState(registro.Observ);
    const exPermitidaAlfaNumerica = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta       
  useEffect(()=>{
 
    getStatus()
    getElementos()
  },[]);

  const getStatus = () => {
    const data = qs.stringify({
      usuario:usuID,     
      idDispositivo:'w845sdc15ds3dc1s'
    });
    const url = "alerta-status";

    function respuesta(aux) {
      if(aux[0].Id>-1)
        { setListaStatus(aux)
        }
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
  };

  const getElementos = () => {
    const data = qs.stringify({
      usuario:usuID,     
      idDispositivo:'w845sdc15ds3dc1s'
    });
    const url = "seguridad-elemento-list";

    function respuesta(aux) {
      if(aux[0].Id>-1)
      {
        aux.sort(function (a, b) { return a.Id - b.Id; }); 
        var auxFiltrado = aux.filter(function (val) { return val.Id !== 0 &&val.Nombre!=="" });
          auxFiltrado.splice(0,0,{
            Id: 0, Nombre: "Sin Asignar",
            Paterno:"", Materno:""
          });
        setListaElementos(auxFiltrado)
      }
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source)
  };

  function onChangeStatus(e){
    setStatus(e.target.value)
  }

  function onChangeElemento(e){
    setElemento(e.target.value)
  }

  function onChanageDireccion(e){
    if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setDireccion(e.target.value);
    }
  }
  function onChangeObservacion(e){
    if(exPermitidaAlfaNumerica.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setObservacion(e.target.value);
    }
  }

  const onChangeObserva2 = e =>
	{  setObservacion(e.target.value)
    /* let expNopermitida = new RegExp('[{}|`]');        
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');             
    let expCadena = new RegExp('[A-Za-z0-9*%$@#_&-+()/.:;!?,<>=]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
        !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
    {//console.log("dejarme cambiar texto");
      setObservacion(e.target.value)
     
    }		 */
	};

  function verificarObservacion(ob){
    let validado=false;
    if(ob && ob!==" ")
    {
      validado=true
    }
    else
    {
      setTipoAdvertencia("warning")
      setMensaje("Ingrese Observación")
      setAviso(true)
      validado=false;
    }
    return validado;
  }

  function verificarElemento(ele){
    let validado=false;
    if(parseInt(ele)!==0)
    { validado=true
    }
    else
    {
      setTipoAdvertencia("warning")
      setMensaje("Seleccione un elemento válido")
      setAviso(true)
      validado=false;
    }
    return validado;
  }

  function handleSubmition(){
    if(parseInt(status)!==0)
    { if(verificarElemento(elemento)&&verificarObservacion(observacion))
      { registrar()
      }
    }
    else
    { setTipoAdvertencia("warning")
      setMensaje("Seleccione un Status válido")
      setAviso(true)
    }
  }

  const envioSocket = () => {
    //console.log("antes de emit");
    const fInicio=moment().format("YYYY-MM-DDTHH:mm:ss");
    socket.emit(
      "AlertBitacoraNew",
      JSON.stringify({
        origen:"com.daduva.atizapanweb",
        AlertId:registro.Id,
        UserId:usuID,
        SegId:elemento,
        fecha:fInicio
      })
    );
  };

  const registrar = () => {    
    const data = qs.stringify({
      Alerta:registro.Id,
      Usuario:usuID,   
      Status:status,
      Publico:registro.PublicoId,
      Elemento: elemento,
      Lat: registro.Lat,
      Lon:registro.Lon,
      Direcc:registro.Direcc,
      Observ:observacion,   
      idDispositivo:'w845sdc15ds3dc1s'
    });
    const url = "alerta-bitacora-nuevo";

    function respuesta(aux) {
      if(aux.respuesta===1)
      {
        setTimeout(() => {
          envioSocket()
        }, 200);
        
        setObservacion("")
        setDireccion("");
        setTipoAdvertencia("success")
        let mensaje="Se actualizo bitácora con éxito"
        setMensaje(mensaje)
        setAviso(true)
        setModalAbierto(false)
        setFuncion()
        toast.success(mensaje, {
          toastId: `edit-alert-not${registro.Id}`,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    llamaApiCargaToken(data, url, respuesta,ErrorGeneral,setEspera,history,source)
  };

  return (
  <Modal
    titulo={titulo} subtitulo={subtitulo} id={registro.SegElemId}
    modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
    guardarDatos={handleSubmition}  mensaje={mensaje} setAviso={setAviso}
    tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'l'}
    btnDeshabilitado={btnDeshabilitado} cargando={espera}
  >
    <form className={classes.root} noValidate autoComplete="off"> 
      <Grid container spacing={1}>
        {listaStatus.length!=0 ? 
        <Grid item>
        <FormControl className={classes.formSelect}>
          <InputLabel id="tipo">Status</InputLabel>
          <Select className={classes.formSelect}
            label="status" id="status" name="status"
            value={status} onChange={onChangeStatus}
          >
            {listaStatus.map((stat, index) => (
              <MenuItem value={stat.Id} key={stat.Id}>
                <em>{stat.Nom}</em>
              </MenuItem>
            ))}
          </Select>
          </FormControl>
        </Grid> :null
        }
      </Grid>
      <Grid container spacing={1}>
        {listaElementos.length!=0 ? 
        <Grid item>
          <FormControl className={classes.formSelectElemento}>
            <InputLabel id="tipo">Elemento</InputLabel>
            <Select className={classes.formSelectElemento}
              label="elemento" id="elemento" name="elemento"
              value={elemento} onChange={onChangeElemento}
            >
              {listaElementos.map((ele, index) => (
                <MenuItem value={ele.Id} key={ele.Id}>
                  <em>{ele.Nombre+" "+ele.Paterno+" "+ele.Materno}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> 
        :null
        }
      </Grid>
      {/*
      <Grid container spacing={1}>
        <Grid item>
          <FormControl className={classes.formAnotacion}>
            <TextField required label="Dirección"
              value={direccion} onChange={onChanageDireccion}id={'direccion'}/> 
          </FormControl>
         </Grid>
      </Grid>
      */}
      {/* <textarea className={classes.formObs} placeholder="Observación"
        value={observacion} onChange={onChangeObservacion} id={'observacion'}
        multiline rows={2} maxRows={4} cols={50} /> */}
      {/* <TextField className={classes.formObs} placeholder="Observación"
        value={observacion} onChange={onChangeObserva2} id={'observacion-Editar1'}
        multiline rows={4} maxRows={4}   variant="outlined" size="small"
      /> */}
      <br/>
      <textarea id="Observacion-1" name="observacion" rows={5}
        cols={100} placeholder="Observación" style={{width: 30+"rem",resize:"none"}} 
        onChange={onChangeObserva2}  //value={observacion} 
      >
        {observacion}
      </textarea>
      {/* <TextField
        id="textAreaEstatus1" label="Observación"
        multiline required fullWidth rows={5} value={observacion}
        variant="outlined" //onChange={onChangeObserva2}
        onChange={e => setObservacion(e.target.value)}
      /> */}
      <br/>
      <br/>
    </form>
  </Modal>
  )
}